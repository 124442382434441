import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dashToEmptySpace } from "../../utilities/dashToEmptySpace";
import data from "../../utilities/constants";
import FilterCardsMessagesButton from "../FilterCardsMessagesButton/index.js";
import {
  MoreVertIcon,
  alertIcon,
  assignIcon,
  deleteIcon,
  searchIcon,
} from "../../assets";
import {
  Badge,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { isFeatureFound } from "../../utilities/isFeatureFound";
import { useDispatch, useSelector } from "react-redux";
import { getAllSegments } from "../../slices/segments";
import { openModal } from "../../slices/modals";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import {
  getAllConversations,
  getAllConversationsByFile,
  getAllConversationsByMeta,
  getConversationQuery,
  getFilteredConversations,
  getUnresolvedConversationsNumber,
  resetPage,
} from "../../slices/conversation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import {
  deleteArchivedConversation,
  unarchiveConversation,
} from "../../slices/archivedConversation";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { LoadingButton } from "@mui/lab";
import { deletePersonalFilter, getPersonalFilters } from "../../slices/filters";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
const Header = ({
  activeFilterCardsMessagesButton,
  setActiveFilterCardsMessagesButton,
  user,
  setChoosenSegment,
  choosenSegment,
}) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [choosePermanentFilter, setChoosePermanentFilter] = useState("");
  const dispatch = useDispatch();
  const { segments, statusSegments } = useSelector((state) => state.segments);
  const { status, unresolvedConversationsNumber, query } = useSelector(
    (state) => state.conversations
  );
  const {
    clientArchivedConversationStored,
    statusClientArchivedConversation,
    statusUnarchiveConversation,
  } = useSelector((state) => state.archivedConversation);
  const {
    personalFilters,
    page,
    hasNextPage,
    statusGetPersonalFilters,
    statusDeletePersonalFilters,
  } = useSelector((state) => state.filters);
  const redirectModal = localStorage.getItem("redirectModal");
  const [search, setSearch] = useState("");
  const { subscription } = useSelector((state) => state.offers);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (
      !Array.isArray(user) &&
      segments?.length === 0 &&
      pathname.includes("/messaging") &&
      open &&
      subscription &&
      isFeatureFound(
        "segments",
        subscription?.sub?.offer?.features,
        subscription?.sub?.extraFeatures
      )
    ) {
      dispatch(getAllSegments(user?.websiteID));
    }
    if (
      !Array.isArray(user) &&
      personalFilters?.length === 0 &&
      pathname.includes("/messaging") &&
      open
    ) {
      dispatch(getPersonalFilters({ websiteID: user?.websiteID, page }));
    }
  }, [open]);
  useEffect(() => {
    !Array.isArray(user) &&
      pathname.includes("/messaging") &&
      unresolvedConversationsNumber === null &&
      dispatch(getUnresolvedConversationsNumber(user?.websiteID));
    pathname.includes("/messaging") &&
      setActiveFilterCardsMessagesButton({
        id: query?.query || 0,
        title:
          data.FILTER_CARDS_MESSAGES.find((el) => el.id === query?.query)
            ?.title || "All",
      });
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <>
      {!pathname.includes("/messaging") ? (
        <div
          className={
            pathname.includes("/archived")
              ? "subsidebar-header-content archived-flex"
              : "subsidebar-header-content"
          }
        >
          <span>
            {dashToEmptySpace(t(pathname?.split("/")[1]).replace(" ", "\xa0"))}
          </span>
          <div className="archived-btns-container">
            {pathname.includes("/archived") && (
              <button
                className="unarchive-btn search-archived-btn"
                onClick={() => {
                  dispatch(
                    openModal("info-modal", {
                      id: "archived-search-modal",
                      websiteID: user?.websiteID,
                      user,
                    })
                  );
                }}
              >
                <LazyLoadImage src={searchIcon} alt="search-icon" />
                <span>{t("Search")}...</span>
              </button>
            )}
            {pathname.includes("/archived") &&
              Object.keys(clientArchivedConversationStored).length > 0 &&
              statusClientArchivedConversation === "succeeded" &&
              (statusUnarchiveConversation === "loading" ? (
                <LoadingButton
                  loading
                  loadingIndicator="Loading…"
                  variant="outlined"
                  style={{ padding: "5px 12px" }}
                >
                  Fetch data
                </LoadingButton>
              ) : (
                <button
                  className="unarchive-btn"
                  onClick={() =>
                    dispatch(
                      unarchiveConversation({
                        websiteID: clientArchivedConversationStored?.website_id,
                        conversationID: clientArchivedConversationStored?._id,
                      })
                    ).then((res) => {
                      if (!res?.error) {
                        showSnackbar({
                          variant: "success",
                          message: "Conversation successfully unarchived",
                        });
                        dispatch(
                          deleteArchivedConversation(
                            clientArchivedConversationStored?._id
                          )
                        );
                        (redirectModal === "false" || redirectModal === null) &&
                          dispatch(
                            openModal("confirm-send", {
                              id: "unarchive-redirect",
                              conversationID:
                                clientArchivedConversationStored?._id,
                              websiteID:
                                clientArchivedConversationStored?.website_id,
                            })
                          );
                      } else {
                        showSnackbar({
                          variant: "error",
                          message: "Fail, please try later",
                        });
                      }
                    })
                  }
                >
                  <UnarchiveOutlinedIcon />
                  <span>{t("Unarchive")}</span>
                </button>
              ))}
          </div>
        </div>
      ) : (
        <div className="subsidebar-header-content filter-cards-container">
          {data.FILTER_CARDS_MESSAGES.map((item) => (
            <Tooltip
              title={
                t(item.title).replace(" ", "\xa0") ===
                t("Unresolved").replace(" ", "\xa0")
                  ? unresolvedConversationsNumber
                  : ""
              }
              placement="right-start"
            >
              <Badge
                badgeContent={
                  t(item.title).replace(" ", "\xa0") ===
                  t("Unresolved").replace(" ", "\xa0")
                    ? unresolvedConversationsNumber
                    : 0
                }
                color="primary"
                className="custom-badge"
              >
                <FilterCardsMessagesButton
                  title={t(item.title).replace(" ", "\xa0")}
                  getConversationsStatus={status}
                  key={item.id}
                  id={item.id}
                  activeFilterCardsMessagesButton={
                    activeFilterCardsMessagesButton
                  }
                  onClick={() => {
                    item.title !== "All"
                      ? dispatch(
                          getFilteredConversations({
                            websiteId: user?.websiteID,
                            key: item.title.toLowerCase(),
                            page: 1,
                          })
                        ).then((res) => {
                          if (res.meta.requestStatus === "fulfilled") {
                            dispatch(resetPage());
                            dispatch(
                              getConversationQuery({
                                websiteId: user?.websiteID,
                                query: item.id,
                              })
                            );
                          }
                        })
                      : dispatch(
                          getAllConversations({
                            websiteID: user?.websiteID,
                            page: 1,
                          })
                        ).then((res) => {
                          if (!res?.error) {
                            dispatch(resetPage());
                            dispatch(getConversationQuery(""));
                          }
                        });
                    setActiveFilterCardsMessagesButton({
                      id: item.id,
                      title: item.title,
                    });
                  }}
                />
              </Badge>
            </Tooltip>
          ))}
          <LazyLoadImage
            src={MoreVertIcon}
            alt="MoreVertIcon"
            className="more-vert-icon"
            id="positioned-button"
            aria-controls={open ? "positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
          <Menu
            id="positioned-menu"
            className="filter-list"
            aria-labelledby="positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disableEnforceFocus
          >
            <MenuItem
              disabled={
                choosenSegment === null && choosePermanentFilter.length === 0
              }
              className="filter-reset-item"
              onClick={() => {
                [
                  "operator",
                  "time",
                  "date",
                  "meta",
                  "department",
                  "file",
                ].includes(choosePermanentFilter) &&
                  dispatch(
                    getAllConversations({ websiteID: user?.websiteID, page: 1 })
                  );
                dispatch(getConversationQuery(""));
                setChoosenSegment(null);
                dispatch(resetPage());
                setChoosePermanentFilter("");
                handleClose();
              }}
            >
              {t("Reset")}
            </MenuItem>

            {statusSegments === "loading" ? (
              <span className="filter-reset-item">`{t("Loading")}...`</span>
            ) : segments?.length === 0 ? (
              <span className="filter-reset-item">No segments to show</span>
            ) : (
              <>
                {segments
                  ?.filter(
                    (segment) =>
                      segment?.name
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      segment?.name?.indexOf(search.toLowerCase()) > -1
                  )
                  ?.map((el) => (
                    <MenuItem
                      key={el._id}
                      className="filter-item"
                      onClick={() => {
                        setChoosenSegment(el);
                        handleClose();
                      }}
                    >
                      {el?.name}
                    </MenuItem>
                  ))}
              </>
            )}
            <hr style={{ border: "1.5px dashed black", margin: "10px 0" }} />
            <span className="filter-reset-item">{t("Personal Filters")}</span>
            {statusGetPersonalFilters === "loading" ? (
              <span className="filter-reset-item">`{t("Loading")}...`</span>
            ) : personalFilters?.length === 0 ? (
              <div className="filter-reset-item">
                No personal filters to show
              </div>
            ) : (
              <>
                {personalFilters?.map((el) => (
                  <div className="personal-filter-wrapper">
                    {" "}
                    <MenuItem
                      key={el._id}
                      className="filter-item"
                      onClick={() => {
                        const {
                          _id,
                          createdAt,
                          updatedAt,
                          name,
                          websiteID,
                          deleted,
                          ...meta
                        } = el;
                        dispatch(
                          getAllConversationsByMeta({
                            websiteID: user?.websiteID,
                            meta,
                            page: 1,
                          })
                        ).then((res) => {
                          if (res.meta.requestStatus === "fulfilled") {
                            setChoosePermanentFilter("meta");
                            dispatch(resetPage());
                            dispatch(
                              getConversationQuery({
                                websiteId: user?.websiteID,
                                query: "meta",
                                meta,
                              })
                            );
                          }
                        });
                        handleClose();
                      }}
                    >
                      {el?.name}
                    </MenuItem>
                    {user?.role?.code === "SUPER-ADMIN" && (
                      <div
                        className="delete-personal-filter-icon"
                        onClick={() =>
                          dispatch(
                            deletePersonalFilter({
                              websiteID: user.websiteID,
                              filterID: el?._id,
                            })
                          )
                        }
                      >
                        {statusDeletePersonalFilters === el?._id ? (
                          <CircularProgress className="delete-personal-filter-circular-progress" />
                        ) : (
                          <LazyLoadImage src={deleteIcon} alt="delete-icon" />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
            {hasNextPage && (
              <div className="expand-icon">
                {statusGetPersonalFilters === "pending" ? (
                  <CircularProgress className="circular-progress-get-personal-filters" />
                ) : (
                  <ExpandMoreOutlinedIcon
                    onClick={() =>
                      dispatch(
                        getPersonalFilters({ websiteID: user?.websiteID, page })
                      )
                    }
                  />
                )}
              </div>
            )}
            <hr style={{ border: "1.5px dashed black", margin: "10px 0" }} />
            <div
              className="filter-operators"
              onClick={() => {
                setChoosePermanentFilter("operator");
                dispatch(
                  openModal("info-modal", {
                    websiteId: user?.websiteID,
                    role: user?.role?.code,
                    id: "filter-conversation-by-operator-modal",
                    icon: assignIcon,
                    title: t("Filter By Operator"),
                    description: t(
                      "In Order To Find The Conversations Of Certain Operator You Can Filter By Operator Name"
                    ),
                    btn_text: t("Filter"),
                  })
                );
                handleClose();
              }}
            >
              {t("By Operator")}
            </div>
            <div
              className="filter-operators"
              onClick={() => {
                setChoosePermanentFilter("date");
                dispatch(
                  openModal("info-modal", {
                    id: "filter-conversations-by-date-modal",
                    websiteId: user?.websiteID,
                    icon: alertIcon,
                    title: t("Filter By Date"),
                    description: t(
                      "In Order To Find The Conversations With Specific  Date You Can Filter By Date"
                    ),
                    btn_text: t("Filter"),
                  })
                );
                handleClose();
              }}
            >
              {t("By Date")}
            </div>
            <div
              className="filter-operators"
              onClick={() => {
                setChoosePermanentFilter("time");
                dispatch(
                  openModal("info-modal", {
                    id: "filter-conversations-by-time-modal",
                    websiteId: user?.websiteID,
                    icon: alertIcon,
                    title: t("Filter By Time"),
                    description: t(
                      "In Order To Find The Conversations With Specific Time  You Can Filter By Time"
                    ),
                    btn_text: t("Filter"),
                  })
                );
                handleClose();
              }}
            >
              {t("By Time")}
            </div>
            <div
              className="filter-operators"
              onClick={() => {
                setChoosePermanentFilter("meta");
                dispatch(
                  openModal("info-modal", {
                    id: "filter-conversations-by-meta-modal",
                    websiteId: user?.websiteID,
                    role: user?.role?.code,
                    icon: alertIcon,
                    title: t("Filter By Meta"),
                    description: t(
                      "In Order To Find The Conversations With Specific Attribute  You Can Filter By Meta"
                    ),
                    btn_text: t("Filter"),
                  })
                );
                handleClose();
              }}
            >
              {t("By meta")}
            </div>
            <div
              className="filter-operators"
              onClick={() => {
                setChoosePermanentFilter("department");
                dispatch(
                  openModal("info-modal", {
                    id: "filter-conversations-by-department-modal",
                    websiteId: user?.websiteID,
                    role: user?.role?.code,
                    icon: alertIcon,
                    title: t("Filter By Department"),
                    description: t(
                      "In Order To Find The Conversations With Specific Attribute  You Can Filter By Department"
                    ),
                    btn_text: t("Filter"),
                  })
                );
                handleClose();
              }}
            >
              {t("By Department")}
            </div>
            <div
              className="filter-operators"
              onClick={() => {
                handleClose();
                setChoosePermanentFilter("file");
                dispatch(
                  getAllConversationsByFile({
                    websiteId: user?.websiteID,
                    page: 1,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    dispatch(resetPage());
                    dispatch(
                      getConversationQuery({
                        websiteId: user?.websiteID,
                        query: "file",
                        page: 1,
                      })
                    );
                  }
                });
              }}
            >
              {t("Contain file(s)")}
            </div>
          </Menu>
        </div>
      )}
    </>
  );
};

export default Header;
