import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { isFound } from "../utilities/isFound";
import { getOperatorById } from "./operaters";
import { sortByLastMessage } from "../utilities/sortByLastMessage";
import { resetStatusMessages } from "./messages";
import { getObjectDiffAttributes } from "../utilities/getObjectDiffAttributes";
import { nanoid } from "nanoid";

const initialState = {
  conversationsStored: [],
  totalPagesForConversations: 1,
  getAllConversationsByOperatorStatus: "idle",
  page: 1,
  totalUnreadMessages: null,
  status: "idle",
  error: null,
  clientConversation: [],
  clientConversationStored: {},
  statusClientConversation: "idle",
  errorClientConversation: null,
  statusUpdatingConversationStatus: "idle",
  getAllConversationsByDateStatus: "idle",
  errorUpdatingConversationStatus: null,
  segmentations: [],
  statusSegmentations: "idle",
  errorSegmentations: null,
  userStatus: [],
  statusBlockConversation: "idle",
  errorBlockConversation: null,
  statusAssignClientToAnotherConversation: "idle",
  errorAssignClientToAnotherConversation: null,
  statusDeleteConversation: "idle",
  errorDeleteConversation: null,
  query: "",
  unresolvedConversationsNumber: null,
  totalDocs: null,
};

export const getAllConversations = createAsyncThunk(
  "conversation/getAllConversations",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/conversations?limit=15&page=${body?.page}`
      );

      data = response.data.data.docs;
      if (response.status === 200) {
        return {
          data,
          totalPages: response.data.data.paginationMeta.totalPages,
          totalDocs: response.data.data.paginationMeta.totalDocs,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateConversationStatus = createAsyncThunk(
  "conversation/updateConversationStatus",
  async (body, thunkApi) => {
    let data;

    try {
      const response = await axios.patch(
        `/websites/${body.websiteId}/conversations/${body.conversationId}/status`
      );
      data = response.data.data;
      if (response.status === 200) {
        ((data?.status === "resolved" && body?.query === 2) ||
          (data?.status === "active" && body?.query === 3)) &&
          thunkApi.dispatch(removeConversation(data?._id));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getOneConversationByClient = createAsyncThunk(
  "conversations/getOneConversationByClient",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.user?.websiteID}/conversations/${body.conversationId}/client`
      );

      data = response.data.data;
      if (response.status === 200) {
        if (
          data?.assigned?.user?._id === body?.userId ||
          data?.assigned?.user === body?.userId
        ) {
          thunkApi.dispatch(getTotalUnreadMessages(body.user?.websiteID));
        }

        return { userId: body.userId, data, user: body?.user };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const blockConversation = createAsyncThunk(
  "conversation/blockConversation",
  async (body) => {
    let data;
    try {
      const response = await axios.put(
        `/websites/${body.websiteId}/conversations/${body.conversationId}/status`,
        { status: body.status }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllConversationsByDepartment = createAsyncThunk(
  "conversation/getAllConversationsByDepartment",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteId}/conversations/department/${body?.departmentId}?limit=15&page=${body?.page}`
      );

      data = response.data.data;
      if (response.status === 200) {
        const { docs, ...meta } = data;
        return {
          data: docs,
          totalPages: meta.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getAllConversationsByFile = createAsyncThunk(
  "conversation/getAllConversationsByFile",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteId}/conversations/hasfiles?limit=15&page=${body?.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        const { docs, ...meta } = data;
        return {
          data: docs,
          totalPages: meta.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllConversationsByOperator = createAsyncThunk(
  "conversation/getAllConversationsByOperator",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteId}/conversations/operator/${body.operatorId}?page=${body.page}&limit=30`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getAllConversationsByDate = createAsyncThunk(
  "conversation/getAllConversationsByDate",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteId}/conversations/date?startDate=${body.startDate}&endDate=${body.endDate}&page=${body.page}&limit=30`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getAllConversationsByMinutes = createAsyncThunk(
  "conversation/getAllConversationsByMinutes",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteId}/conversations/minutes/${body.minutes}?page=${body.page}&limit=30`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getFilteredConversations = createAsyncThunk(
  "conversation/getFilteredConversations",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteId}/conversations/filter?key=${body?.key}&page=${body.page}&limit=30`
      );
      data = response.data.data;

      if (response.status === 200) {
        return {
          data: data.docs,
          totalPages: data.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getAllConversationsByMeta = createAsyncThunk(
  "conversation/getAllConversationsByMeta",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/conversations/search?${
          body?.meta?.staticProp !== undefined && body?.meta?.staticProp !== ""
            ? `search=${body?.meta?.staticProp}`
            : ""
        }${
          body?.meta?.optional?.length > 0
            ? `${body?.meta?.staticProp ? `&` : ""}${body?.meta?.optional
                ?.map((el) => `key[]=${el?.key}&value[]=${el?.value}`)
                ?.join("&")}`
            : ""
        }${
          body?.meta?.status
            ? `${
                body?.meta?.staticProp || body?.meta?.optional?.length > 0
                  ? `&`
                  : ""
              }status=${
                body?.meta?.status === "unresolved"
                  ? "active"
                  : body?.meta?.status
              }`
            : ""
        }&page=${body?.page}&limit=15`
      );
      data = response.data.data;
      if (response.status === 200) {
        return {
          data: data?.docs,
          totalPages: data?.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const assignConversationToAnotherOperator = createAsyncThunk(
  "conversation/assignConversationToAnotherOperator",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.patch(
        `/websites/${body.websiteId}/conversations/${body.conversationId}/assign`,
        { userID: body.operatorId }
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(
          getOperatorById({
            id: data?.assigned?.user?._id,
            websiteId: body.websiteId,
            conversationId: body.conversationId,
            newAssignment: {
              createdAt:
                data?.assignmentHistory?.[data?.assignmentHistory?.length - 1]
                  ?.createdAt,
              oldOperator: body?.oldOperator,
              createdBy:
                data?.assignmentHistory?.[data?.assignmentHistory?.length - 1]
                  ?.createdBy,
            },
            changeOperator: true,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteConversation = createAsyncThunk(
  "conversations/deleteConversation",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/conversations/${body.conversationId}`
      );

      data = response.data.data;
      if (response.status === 200) {
        body?.conversationStatus !== "resolved" &&
          thunkApi.dispatch(getUnresolvedConversationsNumber(body?.websiteID));
        thunkApi.dispatch(resetStatusMessages());
        return body.conversationId;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getTotalUnreadMessages = createAsyncThunk(
  "conversations/getTotalUnreadMessages",
  async (websiteID) => {
    try {
      let data;
      const response = await axios.get(
        `/websites/${websiteID}/conversations/unreaded-number`
      );

      data = response.data.data;
      if (response.status === 200) {
        return data["unreadNumber"];
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getUnresolvedConversationsNumber = createAsyncThunk(
  "conversations/getUnresolvedConversationsNumber",
  async (websiteID) => {
    try {
      let data;
      const response = await axios.get(
        `/websites/${websiteID}/conversations/unresolved-number`
      );

      data = response.data.data?.unresolvedNumber;
      if (response.status === 200) {
        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addFeedback: (state, action) => {
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation?._id === action.payload?.conversationId
            ? {
                ...conversation,
                userFeedback: action.payload?.userFeedBack?.userFeedback,
              }
            : conversation
      );
      state.clientConversation = state.clientConversation.map((conversation) =>
        conversation?._id === action.payload?.conversationId
          ? {
              ...conversation,
              userFeedback: action.payload?.userFeedBack?.userFeedback,
            }
          : conversation
      );
      state.clientConversationStored?._id === action.payload?.conversationId &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          userFeedback: action.payload?.userFeedBack?.userFeedback,
        });
    },
    assignDepartments: (state, action) => {
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation?._id === action.payload?.conversationId
            ? { ...conversation, departments: action.payload?.departments }
            : conversation
      );
      state.clientConversation = state.clientConversation.map((conversation) =>
        conversation?._id === action.payload?.conversationId
          ? { ...conversation, departments: action.payload?.departments }
          : conversation
      );
      state.clientConversationStored?._id === action.payload?.conversationId &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          departments: action.payload.departments,
        });
    },
    removeConversation: (state, action) => {
      state.conversationsStored = state.conversationsStored.filter(
        (conversation) => conversation?._id !== action.payload
      );
    },
    changeMeta: (state, action) => {
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation._id === action.payload?.conversation
            ? (() => {
                return {
                  ...conversation,
                  meta: action.payload?.meta,
                  lastMessage: {
                    content:
                      action.payload?.meta[
                        `${
                          getObjectDiffAttributes(
                            action.payload?.meta,
                            conversation?.meta
                          )?.[0]
                        }`
                      ],
                    createdAt: new Date(Date.now()),
                    from: "CLIENT",
                    _id: nanoid(),
                    conversation: conversation?._id,
                  },
                };
              })()
            : conversation
      );
      state.clientConversation = state.clientConversation.map((conversation) =>
        conversation._id === action.payload?.conversation
          ? {
              ...conversation,
              meta: action.payload?.meta,
              lastMessage: {
                content:
                  action.payload?.meta[
                    `${
                      getObjectDiffAttributes(
                        action.payload?.meta,
                        conversation?.meta
                      )?.[0]
                    }`
                  ],
                createdAt: new Date(Date.now()),
                from: "CLIENT",
                _id: nanoid(),
                conversation: conversation?._id,
              },
            }
          : conversation
      );
      state.clientConversationStored?._id === action.payload?.conversation &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          meta: action.payload?.meta,
          lastMessage: {
            content:
              action.payload?.meta[
                `${
                  getObjectDiffAttributes(
                    action.payload?.meta,
                    state.clientConversationStored?.meta
                  )?.[0]
                }`
              ],
            createdAt: new Date(Date.now()),
            from: "CLIENT",
            _id: nanoid(),
            conversation: state.clientConversationStored?._id,
          },
        });
    },
    resetConversationsStored: (state) => {
      state.clientConversationStored = {};
      state.clientConversation = [];
      state.statusClientConversation = "idle";
    },
    clientConversationStoredSuccess: (state, action) => {
      state.clientConversation.forEach((item) =>
        item._id === action.payload
          ? (state.clientConversationStored = item)
          : null
      );
    },
    getConversationQuery: (state, action) => {
      state.query = action.payload;
    },
    addConversationSuccess: (state, action) => {
      !isFound(state.conversationsStored, action.payload?._id) &&
      state.status === "succeeded"
        ? (() => {
            const Conversation =
              action.payload?.lastMessage?.from === "CLIENT" &&
              action.payload?.status === "resolved"
                ? { ...action.payload, status: "active" }
                : action.payload;
            ((action.payload?.lastMessage?.from === "CLIENT" &&
              action.payload?.status === "resolved") ||
              action.payload?.status === "pending") &&
              (state.unresolvedConversationsNumber =
                state.unresolvedConversationsNumber + 1);
            state.conversationsStored = [
              Conversation,
              ...state.conversationsStored,
            ];
            !isFound(state.clientConversation, Conversation)
              ? (state.clientConversation = [
                  ...state.clientConversation,
                  Conversation,
                ])
              : (state.clientConversation = state.clientConversation.map(
                  (conversation) =>
                    conversation?._id === Conversation?._id
                      ? Conversation
                      : conversation
                ));
            state.clientConversationStored?._id === Conversation?._id &&
              (state.clientConversationStored = Conversation);
          })()
        : (state.conversationsStored = state.conversationsStored.map(
            (conversation) =>
              conversation._id === action.payload?._id
                ? action.payload
                : conversation
          ));

      state.conversationsStored = sortByLastMessage(state.conversationsStored);
    },
    changeLastMessageinConversationSuccess: (state, action) => {
      state.conversationsStored = state.conversationsStored.map(
        (conversation) => {
          conversation._id === action.payload?.conversation &&
            action.payload?.from === "CLIENT" &&
            conversation?.status === "resolved" &&
            (state.unresolvedConversationsNumber =
              state.unresolvedConversationsNumber + 1);
          return conversation._id === action.payload?.conversation
            ? action.payload?.from === "CLIENT" &&
              conversation?.status === "resolved"
              ? {
                  ...conversation,
                  lastMessage: action.payload,
                  status: "active",
                }
              : action.payload?.value &&
                Object.keys(action.payload?.value).length > 0
              ? {
                  ...conversation,
                  lastMessage: action.payload,
                  meta: action.payload?.value,
                }
              : { ...conversation, lastMessage: action.payload }
            : conversation;
        }
      );
      state.conversationsStored = sortByLastMessage(state.conversationsStored);
      state.clientConversation = state.clientConversation.map((item) =>
        item?._id === action.payload?.conversation
          ? item?.status === "resolved" && action.payload?.from === "CLIENT"
            ? { ...item, status: "active", lastMessage: action.payload }
            : action.payload?.value &&
              Object.keys(action.payload?.value).length > 0
            ? {
                ...item,
                lastMessage: action.payload,
                meta: action.payload?.value,
              }
            : { ...item, lastMessage: action.payload }
          : item
      );
      state.clientConversationStored?._id === action.payload?.conversation &&
        state.clientConversationStored?.status === "resolved" &&
        action.payload?.from === "CLIENT" &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          status: "active",
        });
      state.clientConversationStored?._id === action.payload?.conversation &&
        action.payload?.value &&
        Object.keys(action.payload?.value).length > 0 &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          meta: action.payload?.value,
        });
    },
    addSegmentInConversationSuccess: (state, action) => {
      state.clientConversation = state.clientConversation.map((item) =>
        item?._id === action.payload?.conversationId
          ? {
              ...item,
              segments: [...item.segments, action.payload?.segment],
            }
          : item
      );
      state.clientConversationStored = {
        ...state.clientConversationStored,
        segments: [
          ...state.clientConversationStored.segments,
          action.payload.segment,
        ],
      };
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation?._id === state.clientConversationStored?._id
            ? {
                ...conversation,
                segments: [
                  ...conversation.segments,
                  action.payload.segment._id,
                ],
              }
            : conversation
      );
    },
    deleteSegmentInConversationSuccess: (state, action) => {
      state.clientConversation = state.clientConversation.map((item) => {
        return item?._id === action.payload?.conversationId
          ? {
              ...item,
              segments: item.segments.filter(
                (segment) => segment._id !== action.payload?.segment?._id
              ),
            }
          : item;
      });
      state.clientConversationStored.segments =
        state.clientConversationStored.segments.filter(
          (segment) => segment._id !== action.payload?.segment?._id
        );
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation?._id === state.clientConversationStored?._id
            ? {
                ...conversation,
                segments: conversation?.segments?.filter(
                  (segment) => segment !== action.payload.segment?._id
                ),
              }
            : conversation
      );
    },
    assignOperatorSuccess: (state, action) => {
      state.clientConversation = state.clientConversation.map((item) =>
        action.payload?.changeOperator
          ? (() => {
              return item._id === action.payload.conversationId
                ? {
                    ...item,
                    assigned: { ...item.assigned, user: action.payload.data },
                    assignmentHistory: [
                      ...item.assignmentHistory,
                      {
                        ...action.payload.newAssignment,
                        newOperator: action.payload.data,
                      },
                    ],
                  }
                : item;
            })()
          : (() => {
              return item._id === action.payload.conversationId
                ? {
                    ...item,
                    assigned: { ...item.assigned, user: action.payload.data },
                  }
                : item;
            })()
      );

      state.clientConversation.forEach((item) =>
        item._id === action.payload.conversationId
          ? (state.clientConversationStored = item)
          : null
      );
      state.conversationsStored = state.conversationsStored.map((item) =>
        item?._id === action.payload.conversationId
          ? {
              ...item,
              assigned: {
                ...item.assigned,
                user: state?.clientConversationStored?.assigned?.user,
              },
            }
          : item
      );
    },
    unreadMessageSuccess: (state, action) => {
      state.conversationsStored = state.conversationsStored.map((item) => {
        item._id === action.payload &&
          item.unread === 0 &&
          (state.totalUnreadMessages = state.totalUnreadMessages + 1);
        return item._id === action.payload
          ? { ...item, unread: item.unread + 1 }
          : item;
      });
    },
    getConvByClientSuccess: (state, action) => {
      if (
        action.payload.data.assigned?.user?._id === action.payload.userId ||
        action.payload.data.assigned?.user === action.payload.userId
      ) {
        state.conversationsStored = state.conversationsStored.map((item) =>
          item._id === action.payload.data._id
            ? Object.assign(action.payload.data, {
                lastMessage: item.lastMessage,
                unread: 0,
              })
            : item
        );
      }
    },
    getUserStatusSuccess: (state, action) => {
      state.userStatus = [
        ...state.userStatus,
        { _id: action.payload.conversationId, status: action.payload.status },
      ];
    },
    changeUserStatusSuccess: (state, action) => {
      !isFound(state.userStatus, action.payload._id)
        ? (state.userStatus = [
            ...state.userStatus,
            { _id: action.payload._id, status: action.payload.status },
          ])
        : (state.userStatus = state.userStatus.map((item) =>
            item._id === action.payload._id
              ? { ...item, status: action.payload.status }
              : item
          ));
    },
    incrementPageForConversationsSuccess: (state) => {
      state.page = state.page + 1;
    },
    resetPage: (state) => {
      state.page = 2;
    },
    assignedToMeSuccess: (state, action) => {
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation?._id === action.payload?.conversation
            ? {
                ...conversation,
                status:
                  action.payload?.oldOperator === null &&
                  action.payload?.createdAt === null
                    ? "active"
                    : conversation?.status,
                assigned: {
                  ...conversation?.assigned,
                  user: action.payload?.operator,
                },
              }
            : conversation
      );
      state.clientConversation = state.clientConversation.map((item) =>
        item?._id === action.payload?.conversation
          ? {
              ...item,
              status:
                action.payload?.oldOperator === null &&
                action.payload?.createdAt === null
                  ? "active"
                  : item?.status,
              assigned: {
                ...item?.assigned,
                user: action.payload?.operator,
              },
              assignmentHistory:
                action.payload?.oldOperator !== null &&
                action.payload?.createdAt !== null
                  ? [
                      ...item?.assignmentHistory,
                      {
                        newOperator: action.payload?.operator,
                        oldOperator: action.payload?.oldOperator,
                        createdBy: action.payload?.operator,
                        createdAt: action.payload?.createdAt,
                      },
                    ]
                  : [...item?.assignmentHistory],
            }
          : item
      );
      state.clientConversationStored?._id === action.payload?.conversation &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          status:
            action.payload?.oldOperator === null &&
            action.payload?.createdAt === null
              ? "active"
              : state.clientConversationStored?.status,
          assigned: {
            ...state.clientConversationStored?.assigned,
            user: action.payload?.operator,
          },
          assignmentHistory:
            action.payload?.oldOperator !== null &&
            action.payload?.createdAt !== null
              ? [
                  ...state.clientConversationStored?.assignmentHistory,
                  {
                    newOperator: action.payload?.operator,
                    oldOperator: action.payload?.oldOperator,
                    createdBy: action.payload?.operator,
                    createdAt: action.payload?.createdAt,
                  },
                ]
              : [...state.clientConversationStored?.assignmentHistory],
        });
    },
    resetUnreadMessagesSuccess: (state, action) => {
      state.conversationsStored = state.conversationsStored?.map((item) =>
        item?._id === action.payload?.conversation
          ? { ...item, unread: 0 }
          : item
      );
    },
    toggleConversationStatusSuccess: (state, action) => {
      // action.payload?.status === "resolved" &&
      //   (state.unresolvedConversationsNumber =
      //     state.unresolvedConversationsNumber - 1);
      // action.payload?.status === "active" &&
      //   (state.unresolvedConversationsNumber =
      //     state.unresolvedConversationsNumber + 1);

      state.conversationsStored = state.conversationsStored?.map((item) =>
        item?._id === action.payload?.conversationId
          ? { ...item, status: action.payload?.status }
          : item
      );
      state.clientConversation = state.clientConversation?.map((item) =>
        item?._id === action.payload?.conversationId
          ? { ...item, status: action.payload?.status }
          : item
      );
      state.clientConversationStored?._id === action.payload?.conversationId &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          status: action.payload?.status,
        });
    },
    resetStatusClientConversationSuccess: (state) => {
      state.statusClientConversation = "idle";
    },
    assignConversationSuccess: (state, action) => {
      let conversationAssigned;
      state.conversationsStored = state.conversationsStored.map(
        (conversation) => {
          return conversation?._id === action.payload?.conversationId
            ? (() => {
                conversationAssigned = {
                  ...conversation,
                  assigned: {
                    ...conversation?.assigned,
                    user: action.payload?.user,
                  },
                  status: "active",
                  unread: 0,
                };
                return conversationAssigned;
              })()
            : conversation;
        }
      );
      state.clientConversation = state.clientConversation.map((item) =>
        item._id === action.payload.conversationId ? conversationAssigned : item
      );
      state.clientConversationStored?._id === action.payload?.conversationId &&
        (state.clientConversationStored = conversationAssigned);
    },
    addPrivateNotesToConversationSuccess: (state, action) => {
      Array.isArray(action.payload?.data)
        ? (() => {
            state.conversationsStored = state.conversationsStored.map(
              (conversation) =>
                conversation?._id === action.payload?.conversationId
                  ? { ...conversation, notes: action.payload?.data }
                  : conversation
            );
            state.clientConversation = state.clientConversation.map(
              (conversation) =>
                conversation?._id === action.payload?.conversationId
                  ? { ...conversation, notes: action.payload?.data }
                  : conversation
            );
            state.clientConversationStored?._id ===
              action.payload?.conversationId &&
              (state.clientConversationStored = {
                ...state.clientConversationStored,
                notes: action.payload?.data,
              });
          })()
        : (() => {
            state.conversationsStored = state.conversationsStored.map(
              (conversation) =>
                conversation?._id === action.payload?.conversationId
                  ? {
                      ...conversation,
                      notes: [...conversation?.notes, action.payload?.data],
                    }
                  : conversation
            );
            state.clientConversation = state.clientConversation.map(
              (conversation) =>
                conversation?._id === action.payload?.conversationId
                  ? {
                      ...conversation,
                      notes: [...conversation?.notes, action.payload?.data],
                    }
                  : conversation
            );
            state.clientConversationStored?._id ===
              action.payload?.conversationId &&
              (state.clientConversationStored = {
                ...state.clientConversationStored,
                notes: [
                  ...state.clientConversationStored?.notes,
                  action.payload?.data,
                ],
              });
          })();
    },
  },
  extraReducers: {
    [getAllConversations.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      state.totalDocs = action.payload.totalDocs;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.conversationsStored = action.payload.data;
          })()
        : (state.conversationsStored = state.conversationsStored.concat(
            action.payload.data
          ));
    },
    [getAllConversations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getAllConversationsByDepartment.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByDepartment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.conversationsStored = action.payload.data;
          })()
        : (state.conversationsStored = state.conversationsStored.concat(
            action.payload.data
          ));
    },
    [getAllConversationsByDepartment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getAllConversationsByFile.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByFile.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.conversationsStored = action.payload.data;
          })()
        : (state.conversationsStored = state.conversationsStored.concat(
            action.payload.data
          ));
    },
    [getAllConversationsByFile.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getFilteredConversations.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getFilteredConversations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.conversationsStored = action.payload.data;
          })()
        : (state.conversationsStored = state.conversationsStored.concat(
            action.payload.data
          ));
    },
    [getFilteredConversations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getAllConversationsByMeta.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByMeta.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.conversationsStored = action.payload.data;
          })()
        : (state.conversationsStored = state.conversationsStored.concat(
            action.payload.data
          ));
    },
    [getAllConversationsByMeta.rejected]: (state) => {
      state.status = "failed";
    },
    [getAllConversationsByOperator.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByOperator.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (state.conversationsStored = sortByLastMessage(action.payload.docs))
        : (state.conversationsStored = state.conversationsStored.concat(
            sortByLastMessage(action.payload.docs)
          ));
    },
    [getAllConversationsByOperator.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getAllConversationsByMinutes.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByMinutes.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (state.conversationsStored = sortByLastMessage(action.payload.docs))
        : (state.conversationsStored = state.conversationsStored.concat(
            sortByLastMessage(action.payload.docs)
          ));
    },
    [getAllConversationsByMinutes.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getAllConversationsByDate.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.status = "loading")
        : (state.status = "pending");
    },
    [getAllConversationsByDate.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.totalPagesForConversations = action.payload.totalPages;

      action?.meta?.arg?.page === 1
        ? (state.conversationsStored = sortByLastMessage(action.payload.docs))
        : (state.conversationsStored = state.conversationsStored.concat(
            sortByLastMessage(action.payload.docs)
          ));
    },
    [getAllConversationsByDate.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getOneConversationByClient.pending]: (state) => {
      state.statusClientConversation = "loading";
    },
    [getOneConversationByClient.fulfilled]: (state, action) => {
      state.statusClientConversation = "succeeded";
      !isFound(state.clientConversation, action.payload.data._id)
        ? (state.clientConversation = [
            ...state.clientConversation,
            {
              ...action.payload.data,
              assigned:
                typeof action.payload.data?.assigned?.user !== "object" &&
                action.payload?.user?.role?.code === "OPERATOR"
                  ? {
                      ...action.payload.data?.assigned,
                      user: action.payload?.user,
                    }
                  : action.payload.data?.assigned,
            },
          ])
        : (state.clientConversation = state.clientConversation.map((item) =>
            item._id === action.payload.data._id &&
            (item.status === "pending" || item?.dropped)
              ? {
                  ...action.payload.data,
                  assigned:
                    typeof action.payload.data?.assigned?.user !== "object" &&
                    action.payload?.user?.role?.code === "OPERATOR"
                      ? {
                          ...action.payload.data?.assigned,
                          user: action.payload?.user,
                        }
                      : item?.dropped
                      ? action.payload.data?.assigned
                      : item?.assigned,
                }
              : item
          ));

      state.clientConversation.forEach((item) =>
        item._id === action.payload.data._id
          ? (state.clientConversationStored = item)
          : null
      );

      if (
        action.payload.data.assigned?.user?._id === action.payload.userId ||
        action.payload.data.assigned?.user === action.payload.userId
      ) {
        state.conversationsStored = state.conversationsStored.map((item) =>
          item._id === action.payload.data._id
            ? Object.assign(
                typeof action.payload.data?.assigned?.user !== "object" &&
                  action.payload?.user?.role?.code === "OPERATOR"
                  ? {
                      ...action.payload.data,
                      assigned: {
                        ...action.payload.data?.assigned,
                        user: action.payload?.user,
                      },
                    }
                  : action.payload.data,
                {
                  lastMessage: item.lastMessage,
                  unread: 0,
                }
              )
            : item
        );
      }
    },
    [getOneConversationByClient.rejected]: (state, action) => {
      state.status = "failed";
      state.errorClientConversation = action.payload;
    },
    [updateConversationStatus.pending]: (state) => {
      state.statusUpdatingConversationStatus = "loading";
    },
    [updateConversationStatus.fulfilled]: (state, action) => {
      state.statusUpdatingConversationStatus = "succeeded";
      action.payload.status === "resolved"
        ? (state.unresolvedConversationsNumber =
            state.unresolvedConversationsNumber - 1)
        : (state.unresolvedConversationsNumber =
            state.unresolvedConversationsNumber + 1);
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation._id === action.payload._id
            ? { ...conversation, status: action.payload.status }
            : conversation
      );
      state.clientConversation = state.clientConversation.map((item) =>
        item._id === action.payload._id && item.status !== action.payload.status
          ? { ...item, status: action.payload.status }
          : item
      );
      state.clientConversationStored?._id === action.payload._id &&
        (state.clientConversationStored = {
          ...state.clientConversationStored,
          status: action.payload.status,
        });
    },
    [updateConversationStatus.rejected]: (state, action) => {
      state.statusUpdatingConversationStatus = "failed";
      state.errorClientConversation = action.payload;
    },
    [blockConversation.pending]: (state) => {
      state.statusBlockConversation = "loading";
    },
    [blockConversation.fulfilled]: (state, action) => {
      state.statusBlockConversation = "succeeded";
      state.conversationsStored = state.conversationsStored.map(
        (conversation) =>
          conversation._id === action.payload._id
            ? Object.assign(conversation, {
                lastMessage: conversation.lastMessage,
                isBanned: action.payload.isBanned,
              })
            : conversation
      );
      state.clientConversation = state.clientConversation.map((item) =>
        item._id === action.payload._id &&
        item.isBanned !== action.payload.isBanned
          ? { ...item, isBanned: action.payload.isBanned }
          : item
      );

      state.clientConversation.forEach((item) =>
        item._id === action.payload._id
          ? (state.clientConversationStored = item)
          : null
      );
    },
    [blockConversation.rejected]: (state, action) => {
      state.statusBlockConversation = "failed";
      state.errorBlockConversation = action.payload;
    },
    [assignConversationToAnotherOperator.pending]: (state) => {
      state.statusAssignClientToAnotherConversation = "loading";
    },
    [assignConversationToAnotherOperator.fulfilled]: (state) => {
      state.statusAssignClientToAnotherConversation = "succeeded";
    },
    [assignConversationToAnotherOperator.rejected]: (state, action) => {
      state.statusAssignClientToAnotherConversation = "failed";
      state.errorAssignClientToAnotherConversation = action.error?.message;
    },
    [deleteConversation.pending]: (state) => {
      state.statusDeleteConversation = "loading";
    },
    [deleteConversation.fulfilled]: (state, action) => {
      state.statusDeleteConversation = "succeeded";
      state.conversationsStored = state.conversationsStored.filter(
        (conversation) => conversation._id !== action.payload
      );
      state.statusClientConversation = "idle";
    },
    [deleteConversation.rejected]: (state, action) => {
      state.statusDeleteConversation = "failed";
      state.errorDeleteConversation = action.error?.message;
    },
    [getTotalUnreadMessages.fulfilled]: (state, action) => {
      state.totalUnreadMessages = action.payload;
    },
    [getUnresolvedConversationsNumber.fulfilled]: (state, action) => {
      state.unresolvedConversationsNumber = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export default slice;

export const {
  clientConversationStoredSuccess,
  addConversationSuccess,
  changeLastMessageinConversationSuccess,
  addSegmentInConversationSuccess,
  deleteSegmentInConversationSuccess,
  assignOperatorSuccess,
  unreadMessageSuccess,
  getConvByClientSuccess,
  getUserStatusSuccess,
  changeUserStatusSuccess,
  incrementPageForConversationsSuccess,
  assignedToMeSuccess,
  resetUnreadMessagesSuccess,
  toggleConversationStatusSuccess,
  resetStatusClientConversationSuccess,
  changeMeta,
  assignConversationSuccess,
  getConversationQuery,
  resetPage,
  addPrivateNotesToConversationSuccess,
  resetConversationsStored,
  removeConversation,
  assignDepartments,
  addFeedback,
} = slice.actions;

export const clientConversationStored = (id) => async (dispatch, getState) => {
  try {
    await dispatch(clientConversationStoredSuccess(id));
  } catch (error) {
    return console.error(error.message);
  }
};

export const addConversation = (conversation) => async (dispatch) => {
  try {
    await dispatch(addConversationSuccess(conversation));
  } catch (error) {
    return console.error(error.message);
  }
};

export const changeLastMessageinConversation =
  (message) => async (dispatch) => {
    try {
      await dispatch(changeLastMessageinConversationSuccess(message));
    } catch (error) {
      return console.error(error.message);
    }
  };

export const addSegmentInConversation = (data) => async (dispatch) => {
  try {
    await dispatch(addSegmentInConversationSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const deleteSegmentInConversation = (data) => async (dispatch) => {
  try {
    await dispatch(deleteSegmentInConversationSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const assignOperator = (data) => async (dispatch) => {
  try {
    await dispatch(assignOperatorSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const unreadMessage = (data) => async (dispatch) => {
  try {
    dispatch(unreadMessageSuccess(data?.conversationId));
  } catch (error) {
    return console.error(error.message);
  }
};

export const getConvByClient = (body) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/websites/${body.user?.websiteID}/conversations/${body.conversationId}/client`
    );
    if (response.status === 200) {
      if (
        response.data.data.assigned?.user?._id === body.userId ||
        response.data.data.assigned?.user === body.userId
      ) {
        dispatch(getTotalUnreadMessages(body.user?.websiteID));
      }
      dispatch(
        getConvByClientSuccess({
          userId: body.userId,
          data: response.data.data,
        })
      );
    }

    return response.data.data;
  } catch (error) {
    return console.error(error.message);
  }
};

export const getUserStatus = (data) => async (dispatch) => {
  try {
    dispatch(getUserStatusSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const changeUserStatus = (data) => async (dispatch) => {
  try {
    dispatch(changeUserStatusSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const incrementPageForConversations = () => async (dispatch) => {
  try {
    dispatch(incrementPageForConversationsSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};
export const assignedToMe = (data) => async (dispatch) => {
  try {
    dispatch(assignedToMeSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const resetUnreadMessages = (data) => async (dispatch) => {
  try {
    dispatch(resetUnreadMessagesSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const toggleConversationStatus = (data) => async (dispatch) => {
  try {
    dispatch(toggleConversationStatusSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};
export const resetStatusClientConversation = (data) => async (dispatch) => {
  try {
    dispatch(resetStatusClientConversationSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};
export const assignConversation = (data) => async (dispatch) => {
  try {
    dispatch(assignConversationSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const addPrivateNotesToConversation = (data) => async (dispatch) => {
  try {
    dispatch(addPrivateNotesToConversationSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};
