import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation } from "react-router-dom";

const steps = ["", "", ""];

export default function HorizontalLinearStepper({ children }) {
  const { pathname } = useLocation();

  const activeStep = pathname.split("/")[2]?.split("-")[2];

  return (
    <div id="login-container">
      <Box className="box-form" sx={{ minHeight: activeStep === 2 && "auto" }}>
        <Stepper activeStep={activeStep} style={{ padding: "37px 48px" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  sx={{
                    color:
                      index == activeStep - 1
                        ? "#6932FA !important"
                        : "text.secondary",
                    "& .MuiStepIcon-root": {
                      color:
                        index == activeStep - 1
                          ? "#6932FA !important"
                          : "text.disabled",
                    },
                    ".MuiStepIcon-root.Mui-completed": {
                      color: "text.disabled",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {children}
      </Box>
    </div>
  );
}
