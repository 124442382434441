export const isFeatureFound = (feature, array = [], extraArray = []) => {
  const lowerCaseFeature = feature.toLowerCase();

  return (
    array.some(
      (item) => item?.feature?.label?.toLowerCase() === lowerCaseFeature
    ) ||
    extraArray.some(
      (item) => item?.feature?.label?.toLowerCase() === lowerCaseFeature
    )
  );
};
