import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../hooks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isFeatureFound } from "../utilities/isFeatureFound";
import data from "../utilities/constants";
import { removeAndConcat } from "../utilities/removeAndConcat";
const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { subscription } = useSelector((state) => state.offers);
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  } else if (
    pathname?.split("/")?.length === 3 &&
    (pathname?.split("/")[2] === "shortcut-message" ||
      pathname?.split("/")[2] === "clients") &&
    !isFeatureFound(
      pathname?.split("/")[2] === "shortcut-message" ? "shortcuts" : "clients",
      subscription?.sub?.offer?.features,
      subscription?.sub?.extraFeatures
    )
  ) {
    return <Redirect to="/settings" />;
  } else if (
    data.FEATURES.find(
      (el) =>
        el.toLowerCase() ===
        removeAndConcat(
          pathname?.split("/")[1]?.toLowerCase() === "archived"
            ? "archiving"
            : pathname?.split("/")[1]?.toLowerCase()
        )
    ) &&
    !isFeatureFound(
      removeAndConcat(
        pathname?.split("/")[1]?.toLowerCase() === "archived"
          ? "archiving"
          : pathname?.split("/")[1]?.toLowerCase()
      ),
      subscription?.sub?.offer?.features,
      subscription?.sub?.extraFeatures
    )
  ) {
    return <Redirect to="/messaging" />;
  }
  return <>{children}</>;
};

export default AuthGuard;
