import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "./../slices/user";
import { reducer as modalsReducer } from "./../slices/modals";
import { reducer as notifierReducer } from "../slices/notifier";
import { reducer as conversationReducer } from "../slices/conversation";
import { reducer as messagesReducer } from "../slices/messages";
import { reducer as operatorsReducer } from "../slices/operaters";
import { reducer as todolistReducer } from "./../slices/todolist";
import { reducer as shortcuts } from "./../slices/shortcuts";
import { reducer as tagsReducer } from "./../slices/tags";
import { reducer as helpdeskReducer } from "../slices/helpdesk";
import { reducer as segmentsReducer } from "../slices/segments";
import { reducer as rolesReducer } from "../slices/roles";
import { reducer as statisticsReducer } from "../slices/statistics";
import { reducer as newMessageReducer } from "../slices/newMessage";
import { reducer as questionReducer } from "../slices/question";
import { reducer as searchReducer } from "../slices/search";
import { reducer as clientsReducer } from "../slices/clients";
import { reducer as notificationsReducer } from "../slices/notifications";
import { reducer as archivedConversationReducer } from "../slices/archivedConversation";
import { reducer as archivedMessagesReducer } from "../slices/archivedMessages";
import { reducer as filtersReducer } from "../slices/filters";
import { reducer as botReducer } from "../slices/bot";
import { reducer as analyticsReducer } from "../slices/analytics";
import { reducer as websiteReducer } from "../slices/website";
import { reducer as offersReducer } from "../slices/offers";

const combinedReducer = combineReducers({
  user: userReducer,
  notifier: notifierReducer,
  modals: modalsReducer,
  operators: operatorsReducer,
  clients: clientsReducer,
  todolist: todolistReducer,
  tags: tagsReducer,
  shortcuts: shortcuts,
  conversations: conversationReducer,
  messages: messagesReducer,
  question: questionReducer,
  helpdesk: helpdeskReducer,
  segments: segmentsReducer,
  roles: rolesReducer,
  statistics: statisticsReducer,
  newMessage: newMessageReducer,
  search: searchReducer,
  notifications: notificationsReducer,
  archivedConversation: archivedConversationReducer,
  archivedMessages: archivedMessagesReducer,
  filters: filtersReducer,
  bot: botReducer,
  analytics: analyticsReducer,
  website: websiteReducer,
  offers: offersReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
