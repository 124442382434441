import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { styled } from "@mui/material/styles";
import SubscriptionBanner from "../../components/SubscriptionBanner";

// Memoizing MainLayout
const MainLayout = React.memo(({ children }) => {

  const RootStyle = styled("div")({
    display: "flex",
  });
  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    minHeight: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
  }));
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <RootStyle>
      <Header onOpenSidebar={() => setOpenSidebar(true)} />
      <Sidebar
        isOpenSidebar={openSidebar}
              onCloseSidebar={() => setOpenSidebar(false)}
      />
      <MainStyle>
        <SubscriptionBanner />
        {children}
      </MainStyle>
    </RootStyle>
  );
});

export default MainLayout;
