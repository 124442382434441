import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isFeatureFound } from "../../utilities/isFeatureFound";
const FilterCardsMessagesButton = ({
  title,
  activeFilterCardsMessagesButton,
  onClick,
  id,
  getConversationsStatus,
  chatBox,
}) => {
  const { query } = useSelector((state) => state.conversations);
  const { subscription } = useSelector((state) => state.offers);
  const { t } = useTranslation();
  return (
    <button
      disabled={
        !chatBox &&
        (["loading", "pending"].includes(getConversationsStatus) ||
          query?.query === id ||
          (query === "" && t(title) === t("All")))
      }
      className={
        activeFilterCardsMessagesButton?.id === id
          ? "filter-cards-btn active-filter-cards-btn"
          : "filter-cards-btn"
      }
      style={{
        padding:
          localStorage.getItem("i18nextLng") === "ar"
            ? " 5px 24px"
            : "6px 16px",
        cursor:
          title === "Note" &&
          !isFeatureFound(
            "NOTES",
            subscription?.offer?.features,
            subscription?.sub?.extraFeatures
          ) &&
          "auto",
      }}
      onClick={onClick}
    >
      {t(title)}
    </button>
  );
};

export default FilterCardsMessagesButton;
