import React, { useState, useEffect, useRef, useCallback } from "react";
import { TextArea } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Comments from "./Comments";
import IssueItem from "./IssueItem";
import {
  clearData,
  createTodo,
  emptyTagsArray,
  getTitle,
} from "../../../slices/todolist";
import { commentquestion, Close } from "../../../assets";
import { Link } from "react-router-dom";
import { updatetodolist } from "../../../slices/todolist";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../../slices/notifier"; 
import { Button, CircularProgress, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utilities/formatDate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const IssueDetailsComponent = ({
  activeToDoList,
  query,
  user,
  setActiveToDoList,
  source,
  todo,
  setTodo,
  from,
  state,
}) => {
  const [comment, setComment] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = React.useState(0);
  const [updateValues, setUpdateValues] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleChangeTabValues = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeText = (e) => {
    e.preventDefault();
    value === 0 && setDescription(e.target.value);
    value === 1 && setComment(e.target.value);
  };
  const onEmojiClick = (_, emojiObject) => {
    value === 1 && setComment((prevInput) => prevInput + emojiObject.emoji);
    value === 0 && setDescription((prevInput) => prevInput + emojiObject.emoji);
  };
  const { t, i18n } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTags, setAnchorElTags] = useState(null);
  const [anchorElDueDate, setAnchorElDueDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearData());
  }, []);
  const {
    title,
    date,
    operator,
    IssueItemTags,
    statusCreateTodo,
    statusUpdateTodolist,
  } = useSelector((state) => state.todolist);
  const { tags } = useSelector((state) => state.tags);
  useEffect(() => {
    setUpdateValues({
      assigned_to: operator._id,
      title: title?.title,
      due_date: date,
      tags: IssueItemTags,
    });
  }, [IssueItemTags, title, date, operator, IssueItemTags]);
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          anchorEl !== null ||
          anchorElTags !== null ||
          anchorElDueDate !== null
        ) {
          return;
        }

        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler, anchorElTags]);
  };

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    dispatch(
      updatetodolist({
        websiteID: user?.websiteID,
        conversationID: activeToDoList?.conversation,
        data: { sandbox: event.target.checked },
        id: activeToDoList._id,
        query: !query ? "messaging-page" : query,
        user,
      })
    ).then((res) => {
      !res?.error
        ? (() => {
            query === "?sandbox=false" || query === "?sandbox=true"
              ? setActiveToDoList(null)
              : setActiveToDoList(res.payload.data);
            showSnackbar({
              message:
                res.payload.data?.sandbox === true
                  ? `The todo with title ${res.payload.data?.title} is completed`
                  : `The todo with title ${res.payload.data?.title} has returned open`,
              variant: "success",
            });
          })()
        : showSnackbar({
            message: res?.error,
            variant: "error",
          });
    });
  };

  const [titleTodo, setTitleTodo] = useState(activeToDoList?.title);
  useEffect(() => {
    setTitleTodo(activeToDoList?.title);
    setChecked(activeToDoList?.sandbox);
  }, [activeToDoList]);

  const divRef = useRef();
  const handler = useCallback(() => {
    Object.keys(updateValues).map(function (key, index) {
      if (updateValues[key]?.length === 0 || updateValues[key] === undefined) {
        delete updateValues[key];
      }
    });
    if (
      (operator !== "" ||
        title !== "" ||
        date !== "" ||
        (IssueItemTags.length !== 0 && IssueItemTags.length <= tags.length)) &&
      ((state !== null && from === "messaging") ||
        (!state && from !== "messaging")) &&
      !todo
    ) {
      dispatch(
        updatetodolist({
          websiteID: activeToDoList?.websiteID,
          conversationID: activeToDoList?.conversation,
          data: updateValues,
          id: activeToDoList?._id,
          query,
          issueDetails: true,
          user,
        })
      ).then((res) => {
        !res?.error
          ? (() => {
              setActiveToDoList(res.payload.data);

              showSnackbar({
                message: t(`Todo updated successfully`),
                variant: "success",
              });
              dispatch(emptyTagsArray());
            })()
          : (() => {
              showSnackbar({
                message: t(`Please try again`),
                variant: "error",
              });
              dispatch(clearData());
              //dispatch(emptyTagsArray());
              //setActiveToDoList(null);
            })();
      });
    }
  }, [
    title !== "",
    date !== "",
    operator !== "",
    IssueItemTags.length !== 0 && IssueItemTags.length <= tags.length,
    updateValues,
  ]);
  useOnClickOutside(divRef, handler);
  return (
    <div
      className="issues-details-container"
      ref={divRef}
      style={
        i18n.language === "ar"
          ? { borderRight: "1px solid #DCDCDD" }
          : { borderLeft: "1px solid #DCDCDD" }
      }
    >
      <div className="issues-details-header">
        {t("Issue Details")}
        {todo && (
          <IconButton
            aria-label="close"
            onClick={() => setTodo(null)}
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <LazyLoadImage src={Close} alt={"Close"} />
          </IconButton>
        )}
      </div>
      <div className="header-close-wrapper">
        <div className="checkbox-title-wrapper">
          {!todo && (
            <Checkbox
              checked={checked ? checked : false}
              disabled={!activeToDoList || statusUpdateTodolist === "loading"}
              onChange={handleChange}
              sx={{
                color: "#EBEBEB",
                "&.Mui-checked": {
                  color: "#6932FA",
                },
                "& .MuiSvgIcon-root": { fontSize: 35, borderRadius: "20%" },
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}

          <input
            id="Untiled"
            value={titleTodo ? titleTodo : ""}
            placeholder={todo ? t("Write Todo Title") : ""}
            disabled={!activeToDoList}
            onChange={(e) => {
              setTitleTodo(e.target.value);
              dispatch(
                getTitle({ id: activeToDoList?._id, title: e.target.value })
              );
            }}
          />
        </div>
      </div>
      <div id="assigned-to">
        <IssueItem
          activeToDoList={activeToDoList}
          anchorEl={anchorEl}
          divRef={divRef}
          anchorElTags={anchorElTags}
          anchorElDueDate={anchorElDueDate}
          setAnchorEl={setAnchorEl}
          setAnchorElTags={setAnchorElTags}
          setAnchorElDueDate={setAnchorElDueDate}
        />
        {!source && (
          <div className="img-text-answer-wrapper">
            <div className="source-img-text-wrapper">
              <LazyLoadImage src={commentquestion} alt="commentquestion" />
              <span>{t("Source")}:</span>
            </div>
            {!activeToDoList ? (
              <span></span>
            ) : (
              <Link
                to={{
                  pathname: activeToDoList?.messageID
                    ? "/messaging"
                    : "/archived",
                  state: activeToDoList,
                }}
                className="click-here"
              >
                {t("Click here")}
              </Link>
            )}
          </div>
        )}
      </div>
      {activeToDoList && (
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTabValues}
              aria-label="basic tabs example"
              textColor="inherit"
              TabIndicatorProps={{
                sx: { backgroundColor: "#6932FA" },
              }}
            >
              <Tab
                label={t("Description")}
                {...a11yProps(0)}
                className="tab-label"
              />
              {!todo && (
                <Tab
                  label={t("Comments")}
                  {...a11yProps(1)}
                  className="tab-label"
                />
              )}
              {/* <Tab
                label="History"
                {...a11yProps(2)}
                className="tab-label"
                disabled
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <p className="comment-text">{activeToDoList?.description}</p>
            <TextArea
              todo={todo}
              state={description}
              id="todo-description"
              setState={setDescription}
              query={query}
              from={from}
              handleChange={handleChangeText}
              onEmojiClick={onEmojiClick}
              placeholder={t("write your description")}
              className={"textarea-open-page"}
              active={value}
              user={user}
              setActiveToDoList={setActiveToDoList}
              activeToDoList={activeToDoList}
            />
          </TabPanel>
          <TabPanel value={value} index={1} className="tab-comments">
            <div className="comments-container">
              <Comments activeToDoList={activeToDoList} id={value} />
              <TextArea
                state={comment}
                setState={setComment}
                handleChange={handleChangeText}
                onEmojiClick={onEmojiClick}
                placeholder={t("write your comment")}
                active={value}
                user={user}
                activeToDoList={activeToDoList}
                setActiveToDoList={setActiveToDoList}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            History
          </TabPanel>
          {todo && (
            <Button
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseOut}
              disabled={
                operator === "" ||
                date === "" ||
                title?.title === "" ||
                typeof title === "string" ||
                description === "" ||
                statusCreateTodo === "loading"
              }
              id="create-todo-btn"
              className={
                operator === "" ||
                date === "" ||
                title?.title === "" ||
                typeof title === "string" ||
                description === ""
                  ? "disabled-create-todo-button"
                  : ""
              }
              onClick={() => {
                dispatch(
                  createTodo({
                    data: {
                      messageID: todo?._id,
                      assigned_to: operator?._id,
                      description,
                      title: title?.title,
                      tags: IssueItemTags,
                      due_date: formatDate(new Date(date)),
                    },
                    websiteId: user?.websiteID,
                    conversationId: todo?.conversation,
                    user,
                  })
                ).then((res) => {
                  !res?.error
                    ? (() => {
                        setTodo(null);
                        showSnackbar({
                          message: t(`Todo created successfully`),
                          variant: "success",
                        });
                      })()
                    : showSnackbar({
                        message: res?.error?.message,
                        variant: "error",
                      });
                });
              }}
            >
              {statusCreateTodo === "loading" ? (
                <CircularProgress
                  className={
                    isHovering
                      ? "circular-progress-create-todo circular-progress-create-todo-hovering"
                      : "circular-progress-create-todo"
                  }
                />
              ) : (
                t("Create Todo")
              )}
            </Button>
          )}
        </Box>
        // <React.Fragment>
        //   <ListWithTransitionBar
        //     data={data.ISSUE_DETAILS_LIST}
        //     active={active}
        //     setActive={setActive}
        //   />
        //   {active.includes("Comments") && (
        //     <div className="comments-container">
        //       <Comments activeToDoList={activeToDoList} />
        //       <div
        //         className={
        //           activeToDoList.comments.length === 0 && query
        //             ? "textarea-comment-container"
        //             : ""
        //         }
        //       >
        //         <TextArea
        //           state={comment}
        //           setState={setComment}
        //           handleChange={handleChangeText}
        //           onEmojiClick={onEmojiClick}
        //           placeholder="Write your comment"
        //           active={active}
        //           user={user}
        //           activeToDoList={activeToDoList}
        //           setActiveToDoList={setActiveToDoList}
        //         />
        //       </div>
        //     </div>
        //   )}
        //   {/* {active.includes("History") && <History />} */}
        //   {active.includes("Description") && (
        //     <TextArea
        //       state={description}
        //       setState={setDescription}
        //       handleChange={handleChangeText}
        //       onEmojiClick={onEmojiClick}
        //       placeholder="Write your description"
        //       className={"textarea-open-page"}
        //       active={active}
        //       user={user}
        //       setActiveToDoList={setActiveToDoList}
        //       activeToDoList={activeToDoList}
        //     />
        //   )}
        // </React.Fragment>
      )}
    </div>
  );
};

export default IssueDetailsComponent;
