import React, { useEffect, useRef, useState } from "react";
import {
  addSegmentToConversation,
  createSegments,
  deleteSegmentFromConversation,
  deleteSegments,
} from "../../slices/segments";
import { useDispatch, useSelector } from "react-redux";
import { onlySpaces } from "../../utilities/onlySpaces";
import { useTranslation } from "react-i18next";
import { newColorFind } from "../../utilities/randomColor";
import { Button, CircularProgress } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { plusCircleIcon } from "../../assets";
import ClearIcon from "@mui/icons-material/Clear";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { isFeatureFound } from "../../utilities/isFeatureFound";
import { openModal } from "../../slices/modals";

const SegmentsForConversation = ({ userData, user, archived }) => {
  const [display, setDisplay] = useState(false);
  const { clientConversationStored } = useSelector(
    (state) => state.conversations
  );
  const {
    statusCreateSegment,
    statusDeleteSegments,
    statusAddSegmentToConversation,
    segments,
  } = useSelector((state) => state.segments);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        origin: {},
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const handleAddSegmentToConversation = (segmentID) => {
    setDisplay(false);
    dispatch(
      addSegmentToConversation({
        websiteID: user?.websiteID,
        conversationID: clientConversationStored._id,
        segmentID,
      })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            variant: "success",
            message: t("Segment added successfully"),
          })
        : showSnackbar({
            variant: "error",
            message: t(res?.error?.message),
          });
    });
  };

  const onEnterClick = (e) => {
    e.preventDefault();

    if (e.key === "Enter" && !onlySpaces(search)) {
      dispatch(
        createSegments({
          websiteId: user?.websiteID,
          conversationId: clientConversationStored._id,
          segment: search,
        })
      );
      setSearch("");
      setDisplay(false);
    }
  };

  const handleDeleteSegmentFromConversation = (
    websiteID,
    conversationID,
    segment
  ) => {
    dispatch(
      deleteSegmentFromConversation({ websiteID, conversationID, segment })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            variant: "success",
            message: "Deleted from conversation",
          })
        : showSnackbar({
            variant: "error",
            message: res?.error?.message,
          });
    });
  };
  const { t } = useTranslation();
  const { subscription } = useSelector((state) => state.offers);

  return (
    <div
      className="segments-for-conversation-container"
      ref={wrapperRef}
      onClick={() =>
        !isFeatureFound(
          "segments",
          subscription?.sub?.offer?.features,
          subscription?.sub?.extraFeatures
        ) && dispatch(openModal("upgrade-plan", {}))
      }
    >
      <div className="segments-contaienr">
        {userData?.segments?.map((segment, i) => (
          <span
            className="segments-for-conversation-btn"
            style={{ backgroundColor: newColorFind(i), cursor: "pointer" }}
            key={i}
            onClick={() =>
              !archived &&
              handleDeleteSegmentFromConversation(
                user?.websiteID,
                clientConversationStored?._id,
                segment
              )
            }
          >
            {segment?.name}
          </span>
        ))}
      </div>
      {!archived && (
        <div className="segments-for-conversation-wrapper">
          {statusCreateSegment === "loading" ||
          statusDeleteSegments === "loading" ||
          statusAddSegmentToConversation === "loading" ? (
            <CircularProgress className="circular-progress-create-segment" />
          ) : (
            <LazyLoadImage src={plusCircleIcon} />
          )}
          <input
            id="auto-segments"
            name="auto-segments"
            onClick={() => setDisplay(!display)}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="segments-for-conversation-input"
            placeholder={t("Add New Segment")}
            onKeyUp={onEnterClick}
          />
        </div>
      )}

      {display && (
        <div className="auto-container">
          {segments?.length === 0 ? (
            <span className="no-segment-style">
              {t("No segments to show.")} {t("Try create one")}
            </span>
          ) : (
            segments
              ?.filter(
                (segment) =>
                  segment?.name?.toLowerCase().includes(search.toLowerCase()) ||
                  segment?.name?.indexOf(search.toLowerCase()) > -1
              )
              .map((value, i) => {
                return (
                  <div className="option-container">
                    <div
                      className="option"
                      key={i}
                      onClick={() => handleAddSegmentToConversation(value?._id)}
                    >
                      <span>{value?.name}</span>
                    </div>
                    <ClearIcon
                      onClick={() =>
                        dispatch(
                          deleteSegments({
                            websiteId: user?.websiteID,
                            conversationId: clientConversationStored._id,
                            segment: value,
                          })
                        ).then((res) => {
                          !res?.error
                            ? showSnackbar({
                                variant: "success",
                                message: "Segment deleted successfully",
                              })
                            : showSnackbar({
                                variant: "error",
                                message: res?.error?.message,
                              });
                        })
                      }
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                );
              })
          )}
        </div>
      )}
    </div>
  );
};
export default SegmentsForConversation;
