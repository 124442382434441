import React, { useEffect, useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import {
  arrowRightIcon,
  assignIcon,
  edgeIcon,
  firefoxIcon,
  googleIcon,
  operaIcon,
  osIcon,
  safariIcon,
} from "../../../../assets";
import { openModal } from "../../../../slices/modals";
import { CollapseData, TextArea } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getAllSegments } from "../../../../slices/segments";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../../../slices/notifier";
import { isFeatureFound } from "../../../../utilities/isFeatureFound";
import PrivateNote from "./PrivateNote";
import { fetchPrivateNote } from "../../../../slices/todolist";
import {
  assignConversationToAnotherOperator,
  resetUnreadMessages,
} from "../../../../slices/conversation";
import IssueDetailsComponent from "../../../TodoList/Open/IssueDetailsComponent";
import { isFound } from "../../../../utilities/isFound";
import { emitEvent } from "../../../../socket/socket";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import data from "./../../../../utilities/constants";
import SegmentsForConversation from "../../../../components/SegmentsForConversation";

const UserDetails = ({
  user,
  state,
  todo,
  setTodo,
  active,
  setActive,
  activeToDoList,
  setActiveToDoList,
  archived,
}) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { subscription } = useSelector((state) => state.offers);

  const { t } = useTranslation();
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        origin: {},
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [openAssignedOperator, setOpenAssignedOperator] = useState(true);
  const [openSegmentsForConversation, setOpenSegmentsForConversation] =
    useState(true);

  const [openUserInformation, setOpenUserInformation] = useState(true);
  const [openVisitorDevice, setOpenVisitorDevice] = useState(true);
  const [openPrivaceNote, setOpenPrivaceNote] = useState(true);
  const [assignmentHistory, setAssignmentHistory] = useState(true);

  const [newNote, setNewNote] = useState("");
  const handleChange = (e) => {
    setNewNote(e.target.value);
  };
  const {
    clientConversationStored,
    conversationsStored,
    statusAssignClientToAnotherConversation,
    statusClientConversation,
    userStatus,
  } = useSelector((state) => state.conversations);
  const { statusClientArchivedConversation, clientArchivedConversationStored } =
    useSelector((state) => state.archivedConversation);
  const userData = archived
    ? clientArchivedConversationStored
    : clientConversationStored;
  const onEmojiClick = (_, emojiObject) => {
    setNewNote((prevInput) => prevInput + emojiObject.emoji);
  };
  const dispatch = useDispatch();

  const { segments } = useSelector((state) => state.segments);

  useEffect(() => {
    !Array.isArray(user) &&
      segments?.length === 0 &&
      subscription &&
      isFeatureFound(
        "segments",
        subscription?.sub?.offer?.features,
        subscription?.sub?.extraFeatures
      ) &&
      dispatch(getAllSegments(user?.websiteID));
  }, [user]);

  useEffect(() => {
    setTodo(null);
  }, [clientConversationStored]);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (clientConversationStored._id && !clientConversationStored?.notes)
      dispatch(
        fetchPrivateNote({
          websiteID: user?.websiteID,
          conversationID: clientConversationStored._id,
        })
      );
  }, [user?.websiteID, clientConversationStored._id]);

  return (
    (archived ? statusClientArchivedConversation : statusClientConversation) ===
      "succeeded" &&
    (() => {
      return Object.keys(userData)?.length !== 0 && !state && !todo ? (
        <div
          className="right-component-container"
          style={
            i18n.language === "ar"
              ? { borderRight: "1px solid #DCDCDD" }
              : { borderLeft: "1px solid #DCDCDD" }
          }
        >
          <div className="right-card-container">
            <Avatar
              className="right-component-avatar"
              src={userData?.meta?.avatar}
            />
            <Typography className="right-component-name">
              {userData.meta?.name ? userData.meta?.name : "Guest"}{" "}
              {clientConversationStored?.userFeedback?.rating && (
                <LazyLoadImage
                  width="22px"
                  height="22px"
                  src={
                    data?.FEEDBACKS.find(
                      (feedback) =>
                        feedback.id ===
                        clientConversationStored?.userFeedback?.rating
                    )?.expression
                  }
                  alt="feedback"
                />
              )}
            </Typography>
            <Typography className="right-component-profession">
              {isFound(userStatus, userData?._id)
                ? t(userStatus?.find((el) => el?._id === userData?._id)?.status)
                : t("Not available yet")}
            </Typography>
          </div>

          <div className="right-component-scroll-container">
            <CollapseData
              open={openAssignedOperator}
              setOpen={setOpenAssignedOperator}
              collapseTitle={t("Assigned Operator")}
            >
              {userData?.assigned?.user ? (
                <div className="assigned-operator-container">
                  <div className="assigned-operator-content">
                    <Avatar
                      className="assigned-operator-avatar"
                      alt="assigned-operator-avatar"
                      src={userData?.assigned?.user?.avatar}
                    />
                    <Typography className="assigned-operator-name">
                      {userData.assigned?.user
                        ? `${userData.assigned?.user?.firstName} ${userData.assigned?.user?.lastName}`
                        : null}
                    </Typography>
                  </div>
                  {!archived && (
                    <button
                      className="assigned-operator-btn"
                      onClick={() => {
                        user?.role?.code === "OPERATOR" &&
                        user?._id !==
                          clientConversationStored?.assigned?.user?._id
                          ? dispatch(
                              assignConversationToAnotherOperator({
                                websiteId: user?.websiteID,
                                conversationId: clientConversationStored?._id,
                                operatorId: user?._id,
                                oldOperator:
                                  clientConversationStored?.assigned?.user,
                              })
                            ).then((res) => {
                              const conversation = conversationsStored?.find(
                                (conversation) =>
                                  conversation?._id ===
                                  clientConversationStored?._id
                              );
                              !res?.error
                                ? (() => {
                                    showSnackbar({
                                      message: t(
                                        `This conversation has been assigned successfully to you`
                                      ),
                                      variant: "success",
                                    });
                                    emitEvent("ASSIGNED_OPERATOR", {
                                      webSiteId: user?.websiteID,
                                      conversation:
                                        clientConversationStored?._id,
                                      newAssignedOperator: user,
                                      oldOperator:
                                        clientConversationStored?.assigned
                                          ?.user,
                                      createdAt: res?.payload?.createdAt,
                                    });
                                    conversation?.unread !== 0 &&
                                      (() => {
                                        emitEvent("SET_UNREAD_MESSAGES", {
                                          conversation:
                                            clientConversationStored?._id,
                                          websiteID: user?.websiteID,
                                        });
                                        dispatch(
                                          resetUnreadMessages(
                                            clientConversationStored?.lastMessage
                                          )
                                        );
                                        emitEvent("SET_SEEN", {
                                          webSiteId: user?.websiteID,
                                          conversation:
                                            clientConversationStored
                                              ?.lastMessage?.conversation,
                                        });
                                      })();
                                  })()
                                : showSnackbar({
                                    message: "Invalid choice of operator",
                                    variant: "error",
                                  });
                            })
                          : dispatch(
                              openModal("info-modal", {
                                conversationId: clientConversationStored?._id,
                                oldOperator:
                                  clientConversationStored?.assigned?.user,
                                operatorAssigned:
                                  clientConversationStored?.assigned?.user?._id,
                                websiteId: user?.websiteID,
                                user,
                                id: "assign-operator-modal",
                                icon: assignIcon,
                                title: t(
                                  "Assign this client to an new operator"
                                ),
                                description: t(
                                  "if you change the operator you cannot reply, react, create issues, or set reminders. all these functions will be open to your new operator!"
                                ),
                                btn_text: t("Change"),
                              })
                            );
                      }}
                    >
                      {statusAssignClientToAnotherConversation === "loading"
                        ? `${t("Loading")} ...`
                        : (() => {
                            return user?.role?.code === "OPERATOR" &&
                              user?._id !==
                                clientConversationStored?.assigned?.user?._id
                              ? t("Assign To Me")
                              : t("Change");
                          })()}
                    </button>
                  )}
                </div>
              ) : (
                <div className="assigned-operator-container">
                  <div className="assigned-operator-content">
                    <Typography className="assigned-operator-name">
                      {t("Not assigned yet")}
                    </Typography>
                  </div>
                </div>
              )}
            </CollapseData>

            <CollapseData
              open={openSegmentsForConversation}
              setOpen={setOpenSegmentsForConversation}
              collapseTitle={t("Segments For Conversation")}
            >
              <SegmentsForConversation
                userData={userData}
                user={user}
                archived={archived}
              />
            </CollapseData>

            {userData?.meta && (
              <CollapseData
                open={openUserInformation}
                setOpen={setOpenUserInformation}
                collapseTitle={t("User Information")}
                className="user-info-direction"
              >
                {Object.entries(userData?.meta).length === 1 ? (
                  <Typography className="no-user-informations-text">
                    {t("There Is No Informations For This User")}
                  </Typography>
                ) : (
                  <React.Fragment>
                    {Object.entries(userData.meta).map(
                      (item, index) =>
                        item[0] !== "optional" && (
                          <div
                            className="user-information-container"
                            key={index}
                          >
                            <span className="user-information-title">{`${item[0]}:`}</span>
                            <span className="user-information-description">
                              {item[1]}
                            </span>
                          </div>
                        )
                    )}
                    {userData.meta?.optional?.length !== 0 &&
                      userData.meta?.optional?.map((item, index) => (
                        <div className="user-information-container" key={index}>
                          <span className="user-information-title">{`${item.key}:`}</span>
                          <span className="user-information-description">
                            {item.value}
                          </span>
                        </div>
                      ))}
                  </React.Fragment>
                )}
              </CollapseData>
            )}

            {userData?.browserInfo && (
              <CollapseData
                open={openVisitorDevice}
                setOpen={setOpenVisitorDevice}
                collapseTitle={t("Visitor Device")}
              >
                <div className="visitor-device-container">
                  <div className="visitor-devise-content">
                    <LazyLoadImage
                      src={
                        userData?.browserInfo?.name === "chrome"
                          ? googleIcon
                          : userData?.browserInfo?.name === "safari"
                          ? safariIcon
                          : userData?.browserInfo?.name === "firefox"
                          ? firefoxIcon
                          : userData?.browserInfo?.name === "opera"
                          ? operaIcon
                          : userData?.browserInfo?.name === "edge-chromium"
                          ? edgeIcon
                          : null
                      }
                      className="browser-image"
                    />
                    <span className="browser-name">
                      {`${userData?.browserInfo?.name} ${userData?.browserInfo?.version}`}
                    </span>
                  </div>
                  <div className="visitor-devise-content">
                    <LazyLoadImage src={osIcon} className="browser-image" />
                    <span className="browser-name">
                      {userData?.browserInfo?.os}
                    </span>
                  </div>
                </div>
              </CollapseData>
            )}

            <CollapseData
              open={openPrivaceNote}
              setOpen={setOpenPrivaceNote}
              collapseTitle={t("Private Note")}
              className="privace-note-content-collapse"
            >
              {userData?.notes?.map((item) => {
                return (
                  <PrivateNote
                    conversationId={userData?._id}
                    websiteId={user?.websiteID}
                    showSnackbar={showSnackbar}
                    item={item}
                  />
                );
              })}
              {!archived && (
                <TextArea
                  state={newNote}
                  id="private-note"
                  client={clientConversationStored?.assigned?.user?._id}
                  conversationId={clientConversationStored?._id}
                  websiteId={user?.websiteID}
                  handleChange={handleChange}
                  setState={setNewNote}
                  className={"private-note-color"}
                  onEmojiClick={onEmojiClick}
                  placeholder={t("Write a Note")}
                />
              )}
            </CollapseData>

            {userData?.assignmentHistory?.length !== 0 && (
              <CollapseData
                open={assignmentHistory}
                setOpen={setAssignmentHistory}
                collapseTitle={t("Assignment History")}
                className="assignment-history-collapse"
              >
                {userData?.assignmentHistory?.map((item, index) => (
                  <div className="assignment-history-container" key={index}>
                    <div>
                      <span className="old-operator-name">
                        {item?.oldOperator
                          ? `${item?.oldOperator?.firstName} ${item?.oldOperator?.lastName}`
                          : t("Deleted User")}
                      </span>
                      <LazyLoadImage
                        src={arrowRightIcon}
                        alt="arrow-right-icon"
                      />
                      <span className="new-operator-name">
                        {item?.newOperator
                          ? `${item?.newOperator?.firstName} ${item?.newOperator?.lastName}`
                          : t("Deleted User")}
                      </span>
                    </div>
                    <span className="assignment-history-date">
                      {item?.createdBy
                        ? `${t("created by")} ${item?.createdBy?.firstName} ${
                            item?.createdBy?.lastName
                          } ${t("On")} ${new Date(
                            item?.createdAt
                          ).toLocaleDateString(
                            localStorage.getItem("i18nextLng")
                          )}`
                        : ` ${t("On")} ${new Date(
                            item?.createdAt
                          ).toLocaleDateString(
                            localStorage.getItem("i18nextLng")
                          )}`}
                    </span>
                  </div>
                ))}
              </CollapseData>
            )}
          </div>
        </div>
      ) : (
        !archived && (
          <IssueDetailsComponent
            todo={todo}
            setTodo={setTodo}
            user={user}
            from="messaging"
            active={active}
            setActive={setActive}
            activeToDoList={activeToDoList}
            setActiveToDoList={setActiveToDoList}
            source
            state={state}
          />
        )
      );
    })()
  );
};

export default UserDetails;
