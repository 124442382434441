import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  offers: [],
  status: "idle",
  error: null,
  statusCreateSubscription: "idle",
  statusGetOneSubscription: "idle",
  subscription: null,
  subscriptions: [],
  statusGetSubscriptions: "idle",
  errorGetSubscriptions: null,
  subscriptionsMeta: {},
};

export const getOffers = createAsyncThunk("offers/getOffers", async () => {
  let data;
  try {
    const response = await axios.get(`/offers`);
    data = response.data.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message);
  }
});

export const getSubscriptions = createAsyncThunk(
  "offers/getSubscriptions",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/subscriptions?page=${body?.page}&limit=${
          body?.limit?.toString() || 15
        }`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "offers/createSubscription",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/subscriptions`,
        {
          offer: body?.offer?._id,
          quantity: body?.offer?.isFree
            ? 1
            : body?.monthOrYear === "months"
            ? body?.monthsNumber
            : body?.monthsNumber * 12,
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);

export const getOneSubscription = createAsyncThunk(
  "offers/getOneSubscription",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/subscriptions/getOne`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);

const slice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: {
    [getOffers.pending]: (state) => {
      state.status = "loading";
    },
    [getOffers.fulfilled]: (state, action) => {
      state.status = "success";
      state.offers = action.payload.docs;
    },
    [getOffers.rejected]: (state) => {
      state.status = "failed";
    },
    [getSubscriptions.pending]: (state) => {
      state.statusGetSubscriptions = "loading";
    },
    [getSubscriptions.fulfilled]: (state, action) => {
      const { docs, ...meta } = action.payload;
      state.statusGetSubscriptions = "success";
      state.subscriptions = docs;
      state.subscriptionsMeta = meta;
    },
    [getSubscriptions.rejected]: (state, action) => {
      state.statusGetSubscriptions = "failed";
      state.errorGetSubscriptions = action.error.message;
    },
    [createSubscription.pending]: (state) => {
      state.statusCreateSubscription = "loading";
    },
    [createSubscription.fulfilled]: (state) => {
      state.statusCreateSubscription = "success";
    },
    [createSubscription.rejected]: (state) => {
      state.statusCreateSubscription = "failed";
    },
    [getOneSubscription.pending]: (state) => {
      state.statusGetOneSubscription = "loading";
    },
    [getOneSubscription.fulfilled]: (state, action) => {
      state.statusGetOneSubscription = "success";
      state.subscription = action.payload;
    },
    [getOneSubscription.rejected]: (state, action) => {
      state.statusGetOneSubscription = "failed";
      state.subscription = action.error;
    },
  },
});
export const {} = slice.actions;
export const reducer = slice.reducer;
export default slice;
