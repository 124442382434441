import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { offersIcon } from "../../assets";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { createSubscription } from "../../slices/offers";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";

export default function UpgradePlan({ id, open, handleClose, data }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { statusCreateSubscription } = useSelector((state) => state.offers);
  const { user } = useSelector((state) => state.user);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { t } = useTranslation();
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => handleClose(id)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backdropFilter: !data?.id && "blur(5px)" }}
        PaperProps={{ className: "upgrade_plan" }}
      >
        <div className="title">
          <LazyLoadImage src={offersIcon} alt="upgrade plan" />
          <DialogTitle id="alert-dialog-title">
            {data?.id ? data?.title : t("Do you want access to this feature ?")}
          </DialogTitle>
        </div>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data?.id
              ? data?.description
              : t(
                  "Please visit our offers and choose the best plan for your business"
                )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="btns">
          <Button
            className="close_btn"
            onClick={() => handleClose(id)}
            autoFocus
          >
            {t("Cancel")}
          </Button>
          <Button
            className="agree_btn"
            disabled={statusCreateSubscription === "loading"}
            onClick={() => {
              data?.id
                ? dispatch(
                    createSubscription({
                      websiteID: user?.websiteID,
                      offer: data?.offer,
                    })
                  ).then((res) => {
                    handleClose(id);
                    res?.error
                      ? showSnackbar({
                          variant: "error",
                          message: res?.error?.message,
                        })
                      : (showSnackbar({
                          variant: "success",
                          message: t(
                            "Subscription has been created successfully"
                          ),
                        }),
                        (window.location.href = `${process.env.REACT_APP_FRONT_ADMIN_URL}/messaging`));
                  })
                : (pathname !== "/pricing" && history.push("/pricing"),
                  handleClose(id));
            }}
            autoFocus
          >
            {data?.id ? (
              statusCreateSubscription === "loading" ? (
                <CircularProgress className="circular-progress-assign-operator" />
              ) : (
                t("Create")
              )
            ) : (
              t("Discover our offers")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
