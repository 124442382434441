import React, { useState } from "react";
import Picker from "emoji-picker-react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import {
  chooseFile,
  photoIcon,
  sendIcon,
  smileIcon,
  trashIcon,
} from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  createComment,
  createPrivateNote,
  updatetodolist,
} from "../../slices/todolist";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { openModal } from "../../slices/modals";
import { isFeatureFound } from "../../utilities/isFeatureFound";

const TextArea = ({
  state,
  handleChange,
  conversationId,
  websiteId,
  onEmojiClick,
  placeholder,
  from,
  id,
  query,
  className,
  setState, // setState props for todos
  activeToDoList, // activeToDoList props for todos
  setActiveToDoList, // setActiveToDoList props for todos
  user, // user props for todos
  active, // active props for todos
  todo,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [photo, setPhoto] = useState(null);
  const { statusCreateComment, statusUpdateTodolist, createPrivateNoteStatus } =
    useSelector((state) => state.todolist);
  const { subscription } = useSelector((state) => state.offers);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const vals = {
    description: state,
  };
  const handleUploadPhoto = (e) => {
    setPhoto(e.target.files ? e.target.files[0] : null);
  };
  return (
    <div
      className={
        className
          ? `${className} privace-note-input-container`
          : "privace-note-input-container"
      }
      onClick={() =>
        id === "private-note" &&
        !isFeatureFound(
          "privatenotes",
          subscription?.sub?.offer?.features,
          subscription?.sub?.extraFeatures
        ) &&
        dispatch(openModal("upgrade-plan", {}))
      }
    >
      {photo && id !== "private-note" && (
        <div className="photo-comment-container">
          <div className="photo-name-comment-container">
            <div>
              <ImageOutlinedIcon className="image-outlined-icon" />
            </div>
            <Typography className="photo-name-comment-text">
              {photo.name}
            </Typography>
          </div>
          <IconButton onClick={() => setPhoto(null)}>
            <LazyLoadImage src={trashIcon} alt="delete-icon-image-upload" />
          </IconButton>
        </div>
      )}

      <textarea
        id="note-input"
        name="note-input"
        type="text"
        placeholder={placeholder}
        value={state}
        onChange={handleChange}
      />
      <div>
        {id !== "private-note" && (
          <div>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <React.Fragment>
                  <LazyLoadImage
                    src={smileIcon}
                    {...bindTrigger(popupState)}
                    alt={"smileIcon"}
                  />

                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Picker onEmojiClick={onEmojiClick} />
                  </Popover>
                </React.Fragment>
              )}
            </PopupState>
            {active !== 0 && (
              <>
                <label htmlFor="upload-comment-photo">
                  <LazyLoadImage src={photoIcon} alt="photoIcon" />
                </label>
                <input
                  type="file"
                  name="photo"
                  id="upload-comment-photo"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                />
              </>
            )}
            {/* <img src={chooseFile} alt="chooseFile" className="icons" /> */}
          </div>
        )}
        {statusCreateComment === "loading" ||
        (statusUpdateTodolist === "loading" && state !== "" && active === 0) ||
        (createPrivateNoteStatus === "loading" && id === "private-note") ? (
          <CircularProgress className="circular-progress-comment" />
        ) : (
          (from !== "messaging" || active === 0) &&
          state !== "" &&
          !todo && (
            <LazyLoadImage
              src={sendIcon}
              alt="sendIcon"
              className="icons"
              onClick={() => {
                if (active === 0) {
                  dispatch(
                    updatetodolist({
                      websiteID: activeToDoList?.websiteID,
                      conversationID: activeToDoList?.messageID.conversation,
                      data: vals,
                      id: activeToDoList?._id,
                      query,
                      issueDetails: true,
                      user,
                    })
                  ).then((res) => {
                    !res?.error
                      ? (() => {
                          setActiveToDoList(res?.payload?.data);
                          setState("");
                          showSnackbar({
                            variant: "success",
                            message:
                              active === 0
                                ? t("Description created successfully")
                                : t("Comment created successfully"),
                          });
                        })()
                      : showSnackbar({
                          variant: "error",
                          message: t(res?.error?.message),
                        });
                  });
                }
                if (active === 1) {
                  dispatch(
                    createComment({
                      websiteID: user?.websiteID,
                      comment: state,
                      todoID: activeToDoList._id,
                      file: photo,
                    })
                  ).then((res) => {
                    !res?.error
                      ? (() => {
                          setPhoto(null);
                          setActiveToDoList((prev) => {
                            return {
                              ...prev,
                              comments: [...prev.comments, res?.payload?.data],
                            };
                          });

                          setState("");
                          showSnackbar({
                            variant: "success",
                            message:
                              active === 0
                                ? "Description created successfully"
                                : "Comment created successfully",
                          });
                        })()
                      : showSnackbar({
                          variant: "error",
                          message: res?.error?.message,
                        });
                  });
                }

                if (id === "private-note") {
                  dispatch(
                    createPrivateNote({
                      websiteID: websiteId,
                      conversationID: conversationId,
                      data: {
                        content: state,
                      },
                    })
                  ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled") {
                      setState("");
                      showSnackbar({
                        variant: "success",
                        message: t("Private Note Created Successfully"),
                      });
                    } else {
                      showSnackbar({
                        variant: "error",
                        message: res?.error?.message,
                      });
                    }
                  });
                }
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default TextArea;
