import React, { useState } from "react";
import {
  NavLink as RouterLink,
  useLocation,
  useHistory,
} from "react-router-dom";
import { isFeatureFound } from "../../utilities/isFeatureFound";
import { styled } from "@mui/material/styles";
import data from "../../utilities/constants";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Badge,
} from "@mui/material";
import useResponsive from "./../../hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { removeAndConcat } from "../../utilities/removeAndConcat";
import { openModal } from "../../slices/modals";

const ListItemStyle = styled((props) => {
  // Destructure isEnabled from props to avoid passing it to the DOM element
  const { isEnabled, ...other } = props;
  return <ListItemButton disableGutters {...other} />;
})(({ theme, isEnabled }) => ({
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  borderRadius: "9px",
  color: "#7F7F81",
  padding: "7px 5px 7px 5px",
  opacity: isEnabled === false ? 0.5 : 1,
  pointerEvents: isEnabled === false ? "none" : "auto",
  cursor: isEnabled === false ? "not-allowed" : "pointer",

  // You can add additional styles based on isEnabled
  "&:hover": {
    backgroundColor:
      isEnabled === false ? "transparent" : theme.palette.action.hover,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
function NavItem({ item, active, isEnabled }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { totalUnreadMessages } = useSelector((state) => state.conversations);
  const { t } = useTranslation();
  const { unreadNotificationsNumber, assignedNumber } = useSelector(
    (state) => state.notifications
  );
  const isActiveRoot = active === 1 ? true : false;
  const { title, path, icon, info, children } = item;
  const [openNavSection, setOpenNavSection] = useState(isActiveRoot);
  const handleOpenNavSection = () => {
    setOpenNavSection((prev) => !prev);
  };
  const activeRootStyle = {
    color: "#6932FA",
    backgroundColor: "#F0EBFF",

    "&:hover": {
      backgroundColor: "#F0EBFF",
      color: "#6932FA",
      transition: "all 0.5s ease-in-out !important",
    },
  };
  const activeSubStyle = {
    color: "text.primary",
  };
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();

  if (children && !isDesktop) {
    return (
      <>
        <ListItemStyle
          isEnabled={isEnabled}
          onClick={handleOpenNavSection}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>
            {icon &&
              (["To Do List", "Notifications", "Inbox"].includes(title) ? (
                <Badge
                  badgeContent={
                    title === "Notifications"
                      ? unreadNotificationsNumber
                      : title === "To Do List"
                      ? assignedNumber
                      : totalUnreadMessages
                  }
                  color="primary"
                  className="custom-badge"
                >
                  <LazyLoadImage
                    style={{
                      filter:
                        ["To Do List", "Notifications"].includes(title) &&
                        !pathname.includes(path) &&
                        "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                    }}
                    src={icon}
                    alt={path}
                    className={
                      pathname.includes(path)
                        ? "active-sidebar-icon"
                        : "sidebar-icon"
                    }
                  />
                </Badge>
              ) : (
                <LazyLoadImage
                  src={icon}
                  alt={path}
                  style={{
                    filter:
                      title === "Archived" &&
                      !pathname.includes(path) &&
                      "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                  }}
                  className={
                    pathname.includes(path)
                      ? "active-sidebar-icon"
                      : "sidebar-icon"
                  }
                />
              ))}
          </ListItemIconStyle>
          <ListItemText
            disableTypography
            primary={title}
            className="nav-config-title"
            // sx={{ display: "flex !important" }}
          />
          {info && info}
        </ListItemStyle>
        <Collapse in={openNavSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  icon={icon}
                  active={pathname.includes(item.path) ? 1 : 0}
                  sx={{
                    ...(pathname.includes(item.path) && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(pathname.includes(item.path) && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    className="nav-config-title"
                    // sx={{ display: "flex !important" }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItemStyle
      // component={!isEnabled && RouterLink}
      // to={!isEnabled && path}
      // isEnabled={isEnabled}
      onClick={() =>
        !isEnabled
          ? dispatch(openModal("upgrade-plan", {}))
          : history.push(path)
      }
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>
        {icon &&
          (["To Do List", "Notifications", "Inbox"].includes(title) ? (
            <Badge
              badgeContent={
                title === "Notifications"
                  ? unreadNotificationsNumber
                  : title === "To Do List"
                  ? assignedNumber
                  : totalUnreadMessages
              }
              color="primary"
              className="custom-badge"
            >
              <LazyLoadImage
                style={{
                  filter:
                    ["To Do List", "Notifications"].includes(title) &&
                    !pathname.includes(path) &&
                    "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                }}
                src={icon}
                alt={path}
                className={
                  pathname.includes(path)
                    ? "active-sidebar-icon"
                    : "sidebar-icon"
                }
              />
            </Badge>
          ) : (
            <LazyLoadImage
              src={icon}
              alt={path}
              style={{
                filter:
                  title === "Archived" &&
                  !pathname.includes(path) &&
                  "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
              }}
              className={
                pathname.includes(path) ? "active-sidebar-icon" : "sidebar-icon"
              }
            />
          ))}
      </ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={t(title)}
        className="nav-config-title"
      />
      {info && info}
    </ListItemStyle>
  );
}
function NavSection({ NAV_CONFIG, ...other }) {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);

  const { subscription } = useSelector((state) => state.offers);

  return (
    <List
      disablePadding
      sx={{
        marginTop: "48px",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
      }}
    >
      <>
        {data.NAV_CONFIG.map((item) => {
          return item.path === "/pricing" ? (
            user?.role?.code === "SUPER-ADMIN" && (
              <NavItem
                key={item.id}
                item={item}
                active={pathname.includes(item.path) ? 1 : 0}
                isEnabled={true}
              />
            )
          ) : (
            <NavItem
              key={item.id}
              item={item}
              active={pathname.includes(item.path) ? 1 : 0}
              isEnabled={
                data.FEATURES.find(
                  (el) =>
                    el.toLowerCase() ===
                    (item.path.split("/")[1] === "archived"
                      ? "archiving"
                      : removeAndConcat(item.path.split("/")[1]))
                )
                  ? isFeatureFound(
                      item.path.split("/")[1] === "archived"
                        ? "archiving"
                        : removeAndConcat(item.path.split("/")[1]),
                      subscription?.sub?.offer?.features,
                      subscription?.sub?.extraFeatures
                    )
                  : !data.FEATURES.find(
                      (el) =>
                        el.toLowerCase() ===
                        (item.path.split("/")[1] === "archived"
                          ? "archiving"
                          : removeAndConcat(item.path.split("/")[1]))
                    )
              }
            />
          );
        })}
      </>
    </List>
  );
}
export default NavSection;
