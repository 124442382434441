import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneSubscription } from "../../slices/offers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscriptionBanner = () => {
  const { user } = useSelector((state) => state.user);
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.offers);
  const { t } = useTranslation();

  useEffect(() => {
    !Array.isArray(user) &&
      !subscription &&
      dispatch(getOneSubscription(user?.websiteID));
  }, [user]);

  return (
    <>
      {(subscription?.message || subscription?.msg) &&
        modals?.find((el) => el.id === "upgrade-plan") &&
        modals?.find((el) => el.id === "upgrade-plan")?.open === false && (
          <div className="marquee">
            {`${t(subscription?.message || subscription?.msg)}`}{" "}
            -{" "}
            <Link
              to="/pricing"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {t("Upgrade your plan")}
            </Link>
          </div>
        )}
    </>
  );
};

export default SubscriptionBanner;
