import React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { isFeatureFound } from "../utilities/isFeatureFound";

const RoleGuard = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const { subscription } = useSelector((state) => state.offers);

  const isAllowed = () => {
    if (user?.role?.code === "OPERATOR") {
      return false;
    }
    return true;
  };

  if (!isAllowed()) return null;
  else if (
    pathname?.split("/")[2]?.toLowerCase() === "chatbot-configuration" &&
    !isFeatureFound(
      "bot",
      subscription?.sub?.offer?.features,
      subscription?.sub?.extraFeatures
    )
  ) {
    return <Redirect to="/settings" />;
  }

  return <>{children}</>;
};

export default RoleGuard;
