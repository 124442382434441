import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, MenuItem, Popover, Stack, Drawer, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../../hooks";
import data from "./../../utilities/constants";
import Header from "./components/Header";
import NavSection from "../../components/NavSection/NavSection";
import AccountPopover from "../../components/AccountPopover";
import { fetchUser } from "../../slices/user";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../slices/modals";
import {
  addFeedback,
  assignedToMe,
  changeLastMessageinConversation,
  changeMeta,
  changeUserStatus,
  changeUserStatusSuccess,
  getConvByClient,
  getOneConversationByClient,
  getTotalUnreadMessages,
  resetStatusClientConversation,
  resetUnreadMessages,
  toggleConversationStatus,
  unreadMessage,
} from "../../slices/conversation";
import { useHistory, useLocation } from "react-router-dom";
import { addEvent, emitEvent } from "../../socket/socket";
import {
  addMessageToState,
  conversationMessages,
  deleteMessageFromSocket,
  seenByClient,
  updateMessageFromSocket,
} from "../../slices/messages";
import { en, ar, fr, searchSidebarIcon } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  getOpenedReachedTodos,
  getUnreadNotificationsNumber,
  receiveNotification,
} from "../../slices/notifications";
import { isFound } from "../../utilities/isFound";
import { nanoid } from "nanoid";
import soundFile from "../../assets/sounds/sound-effect.mp3";
import { getOneArchivedConversation } from "../../slices/archivedConversation";
import { getArchivedMessages } from "../../slices/archivedMessages";
import { changeColor, getWebsite } from "../../slices/roles";
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: data.DRAWER_WIDTH,
  },
}));
const LANGS = [
  {
    value: "en",
    label: "English",
    icon: en,
  },
  {
    value: "ar",
    label: "Arabic",
    icon: ar,
  },
  {
    value: "fr",
    label: "French",
    icon: fr,
  },
];
export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { user } = useSelector((state) => state.user);
  const { statusGetWebsite } = useSelector((state) => state.roles);
  const history = useHistory();
  const { pathname } = useLocation();
  const { totalUnreadMessages, conversationsStored, clientConversationStored } =
    useSelector((state) => state.conversations);
  const { unreadNotificationsNumber, assignedNumber } = useSelector(
    (state) => state.notifications
  );
  const { messagesStored } = useSelector((state) => state.messages);

  useEffect(() => {
    addEvent("MESSAGE_FROM_SERVER", (data) => {
      // when a new conversation is created
      if (data?.newConversation) {
        dispatch(addMessageToState(data?.conversation));
        dispatch(getTotalUnreadMessages(user?.websiteID));
      } else if (data?.meta) {
        dispatch(changeMeta(data));
        const audio = new Audio(soundFile);
        audio.play();
      } else {
        // when a new message is sent
        !isFound(
          conversationsStored,
          data?.conversation?.lastMessage?.conversation
        )
          ? (() => {
              dispatch(addMessageToState(data?.conversation));
            })()
          : dispatch(addMessageToState(data?.conversation?.lastMessage)).then(
              () => {
                const conversation = conversationsStored?.find(
                  (item) =>
                    item?._id === data?.conversation?.lastMessage?.conversation
                );
                messagesStored[0]?.conversation !==
                  data?.conversation?.lastMessage?.conversation ||
                messagesStored[0]?.length === 0 ||
                (messagesStored[0]?.conversation ===
                  data?.conversation?.lastMessage?.conversation &&
                  conversation?.assigned?.user?._id !== user?._id)
                  ? dispatch(
                      unreadMessage({
                        conversationId:
                          data?.conversation?.lastMessage?.conversation,
                        websiteID: user?.websiteID,
                      })
                    )
                  : user?._id === conversation?.assigned?.user?._id &&
                    dispatch(
                      getConvByClient({
                        conversationId: messagesStored[0]?.conversation,
                        user,
                        websiteId: user?.websiteID,
                        userId: user?._id,
                      })
                    ).then(
                      (res) =>
                        !res?.error &&
                        (() => {
                          emitEvent("SET_UNREAD_MESSAGES", {
                            conversation: messagesStored[0]?.conversation,
                            websiteID: user?.websiteID,
                          });
                          emitEvent("SET_SEEN", {
                            webSiteId: user?.websiteID,
                            conversation: messagesStored[0]?.conversation,
                          });
                        })()
                    );
              }
            );
      }
    });
  }, [messagesStored, conversationsStored, user]);

  useEffect(() => {
    addEvent("ACTIVE", (data) => {
      dispatch(
        changeUserStatusSuccess({ _id: data?.conversationId, status: "active" })
      );
    });

    addEvent("AWAY_FROM_CLIENT", (data) => {
      dispatch(changeUserStatus({ _id: data?.conversationId, status: "away" }));
    });
    addEvent("DISCONNECT_CLIENT", (data) => {
      dispatch(
        changeUserStatus({ _id: data?.conversationId, status: "disconnected" })
      );
    });

    addEvent("SEEN_FROM_CLIENT_FRONT", (data) => {
      if (data) {
        dispatch(seenByClient(data));
      }
    });
    addEvent("UPDATE_MESSAGE_FROM_OPERATOR", (data) => {
      dispatch(changeLastMessageinConversation(data?.lastMessage?.message));
      dispatch(updateMessageFromSocket(data?.lastMessage));
    });
    addEvent("DELETE_MESSAGE_FROM_OPERATOR", (data) => {
      dispatch(
        changeLastMessageinConversation(data?.lastMessage?.last_message)
      );
      dispatch(deleteMessageFromSocket(data?.lastMessage));
    });

    addEvent("UNREAD_MESSAGES", (data) => {
      dispatch(resetUnreadMessages(data));
      dispatch(getTotalUnreadMessages(data?.websiteID));
    });
    addEvent("RESOLVED_FROM_OPERATOR", (data) => {
      dispatch(toggleConversationStatus(data));
    });
    addEvent("FEEDBACK", (data) => {
      dispatch(addFeedback(data));
    });
  }, []);

  useEffect(() => {
    user?.length === 0 && dispatch(fetchUser());
    !Array.isArray(user) &&
      totalUnreadMessages === null &&
      dispatch(getTotalUnreadMessages(user?.websiteID));
    !Array.isArray(user) &&
      unreadNotificationsNumber === null &&
      dispatch(getUnreadNotificationsNumber(user?.websiteID));
    !Array.isArray(user) &&
      user?.role?.code === "OPERATOR" &&
      assignedNumber === null &&
      dispatch(getOpenedReachedTodos(user?.websiteID));
    addEvent("connect", () => {
      user &&
        (() => {
          emitEvent("CONNECT", {
            operatorId: user?._id,
            websiteId: user?.websiteID,
          });
        })();
      console.log("connected");
    });
    addEvent("MESSAGE_FROM_OPERATOR", (data) => {
      if (data) {
        (data?.lastMessage?.mentionedUsers?.find(
          (userId) => user?._id === userId
        ) ||
          data?.lastMessage?.departments?.find(
            (departmentId) => departmentId === user?.department?._id
          )) &&
          data?.lastMessage?.isNote &&
          (() => {
            dispatch(
              getUnreadNotificationsNumber(data?.lastMessage?.webSiteId)
            );
            dispatch(
              receiveNotification({
                code: "NOTE",
                _id: nanoid(),
                seen: false,
                doc: data?._id,
                from: data?.lastMessage?.createdBy,
                createdAt: Date.now(),
              })
            );
          })();
        dispatch(addMessageToState(data));
      }
    });
    addEvent("NEW_ASSIGNED_OPERATOR", (data) => {
      if (data) {
        user?._id === data?.newAssignedOperator?._id &&
          (() => {
            dispatch(getUnreadNotificationsNumber(data?.webSiteId));
            dispatch(
              receiveNotification({
                code: "CONVERSATION_ASSIGN",
                _id: nanoid(),
                seen: false,
                doc: data?.conversation,
                from: data?.user,
                to: data?.newAssignedOperator,
                createdAt: Date.now(),
              })
            );
          })();
        dispatch(
          assignedToMe({
            websiteId: data?.webSiteId,
            conversation: data?.conversation,
            operator: data?.newAssignedOperator,
            oldOperator: data?.oldOperator ? data?.oldOperator : null,
            createdAt: data?.createdAt ? data?.createdAt : null,
          })
        );
      }
    });
    addEvent("DM_USER", (data) => {
      const { conversation, ...message } = data;
      const Conversation = {
        ...conversation,
        lastMessage: message,
        dropped: true,
      };
      dispatch(addMessageToState(Conversation));
    });

    !Array.isArray(user) &&
      statusGetWebsite !== "succeeded" &&
      dispatch(getWebsite(user?.websiteID)).then(
        (res) =>
          !res?.error &&
          res?.payload?.color &&
          dispatch(changeColor(res?.payload?.color))
      );
  }, [user]);

  const dispatch = useDispatch();

  useEffect(() => {
    pathname?.split("messaging")[1]?.length > 1 &&
      !Array.isArray(user) &&
      pathname?.split("/")[2] === user?.websiteID &&
      pathname?.split("/")[3] !== clientConversationStored?._id &&
      (async () => {
        await dispatch(
          getOneConversationByClient({
            conversationId: pathname?.split("/")[3],
            user,
            websiteId: user?.websiteID,
            userId: user?._id,
          })
        ).then((res) => {
          const conversation = conversationsStored?.find(
            (item) => item?._id === pathname?.split("/")[3]
          );
          !res?.error &&
            user?.role?.code === "OPERATOR" &&
            typeof res?.payload?.data?.assigned?.user === "string" &&
            emitEvent("ASSIGNED_OPERATOR", {
              webSiteId: user?.websiteID,
              conversation: pathname?.split("/")[3],
              newAssignedOperator: user,
            });
          !res?.error &&
            (typeof res?.payload?.data?.assigned?.user === "string" ||
              res?.payload?.data?.assigned?.user?._id === user?._id) &&
            conversation?.unread !== 0 &&
            emitEvent("SET_UNREAD_MESSAGES", {
              conversation: conversation?._id,
              websiteID: user?.websiteID,
            });

          !res?.error
            ? dispatch(
                conversationMessages({
                  conversationId: pathname?.split("/")[3],
                  websiteId: user?.websiteID,
                  page: 1,
                })
              )
            : (() => {
                history.push("/messaging");
                dispatch(resetStatusClientConversation());
              })();
        });
      })();

    pathname?.split("archived")[1]?.length > 1 &&
      !Array.isArray(user) &&
      pathname?.split("/")[2] === user?.websiteID &&
      pathname?.split("/")[3] !== clientConversationStored?._id &&
      (async () => {
        await dispatch(
          getOneArchivedConversation({
            websiteID: user?.websiteID,
            conversationID: pathname?.split("/")[3],
          })
        ).then((res) => {
          !res?.error
            ? dispatch(
                getArchivedMessages({
                  websiteID: user?.websiteID,
                  conversationID: pathname?.split("/")[3],
                  page: 1,
                })
              )
            : (() => {
                history.push("/archived");
                dispatch(resetStatusClientConversation());
              })();
        });
      })();
  }, [user]);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const buttonRef = React.createRef();
  const handleKeyDown = (e) => {
    if (e.code === "F3" || ((e.ctrlKey || e.metaKey) && e.code === "KeyF")) {
      e.preventDefault();
      dispatch(
        openModal("info-modal", {
          id: "search-modal",
          websiteID: user?.websiteID,
          user,
        })
      );
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [user?.websiteID]);
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState({});
  const isDesktop = useResponsive("up", "lg");
  useEffect(() => {
    i18n &&
      i18n &&
      LANGS.forEach(
        (lang) =>
          lang.value.includes(
            i18n.language.split("-").length > 1
              ? i18n.language.split("-")[0]
              : i18n.language
          ) && setSelected(lang)
      );
  }, [i18n]);

  const renderContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "25px",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box>
        <Header />
      </Box>
      <NavSection />
      <Box sx={{ flexGrow: 1 }} />
      <div ref={buttonRef}>
        <Box
          sx={{
            px: 2.5,
            pb: 3,
            mt: 10,
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <Stack
              sx={{
                borderRadius: 2,
                maxWidth: "50px",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "#F2F2F4",
                  transition: "all 0.5s ease-in-out",
                },
              }}
            >
              <AccountPopover />
            </Stack>

            <Grid
              container
              onClick={handleOpen}
              sx={{
                padding: "10px",
                borderRadius: "9px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#F2F2F4",
                  transition: "all 0.5s ease-in-out",
                },
              }}
            >
              <LazyLoadImage
                src={selected.icon}
                alt={t(selected.label)}
                style={{ width: "30px", height: "30px" }}
              />
            </Grid>

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  p: 1,
                  mt: -1,
                  width: 194,
                  borderRadius: "12px",
                  boxShadow: "none",
                  border: "1px solid #efefef",

                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <Stack>
                {LANGS.map((option) => (
                  <MenuItem
                    key={option.value}
                    selected={option.value === selected.value}
                    onClick={() => {
                      setSelected(option);
                      i18n.changeLanguage(option.value);
                      handleClose();
                    }}
                    className="selected-language-popover"
                  >
                    <LazyLoadImage
                      alt={t(option.label)}
                      src={option.icon}
                      style={{ width: "26px", height: "26px" }}
                    />
                    {t(option.label)}
                  </MenuItem>
                ))}
              </Stack>
            </Popover>
          </>
          <Grid
            container
            sx={{
              padding: "10px",
              borderRadius: "9px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#F2F2F4",
                transition: "all 0.5s ease-in-out",
              },
            }}
            onClick={() => {
              dispatch(
                openModal("info-modal", {
                  id: "search-modal",
                  websiteID: user?.websiteID,
                  user,
                })
              );
            }}
          >
            <Grid item xs={12}>
              <LazyLoadImage alt={"search-icon"} src={searchSidebarIcon} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          anchor={
            localStorage.getItem("i18nextLng") === "ar" ? "right" : "left"
          }
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          anchor={
            localStorage.getItem("i18nextLng") === "ar" ? "right" : "left"
          }
          sx={{ maxWidth: "98px" }}
          PaperProps={{
            sx: {
              maxWidth: "98px",
              bgcolor: "#FBFBFC",
              borderRightStyle: "straight",
              position: "relative",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
